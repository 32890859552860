import StaticView from './Static.vue'
import { kebabCase } from 'lodash-es'

export function createStaticView ({ page, download }) {
  return {
    name: `${page}-page`,

    metaInfo () {
      const meta = []
      if (this.$te(`page.${kebabCase(page)}-description`)) meta.push({ vmid: 'description', name: 'description', content: this.$t(`page.${kebabCase(page)}-description`) })
      return {
        title: this.$t(`page.${kebabCase(page)}`),
        meta
      }
    },

    render (h) {
      return h(StaticView, { props: { page, download } })
    }
  }
}
