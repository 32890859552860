import { mapState } from 'vuex'
import { assetBasePath } from '@/config'

export default {
  computed: {
    assetBasePath: () => assetBasePath,
    ...mapState({
      token: ({ auth: { token } }) => token,
      isAuthenticated: (_, { isAuthenticated }) => isAuthenticated,
      currentName: ({ current: { user } }) => user ? `${user.firstName} ${user.lastName}` : null,
      currentUser: ({ current: { user } }) => user,
      currentAffiliate: (_, { affiliate }) => affiliate,
      currentAffiliateName: (_, { affiliate }) => affiliate ? affiliate.name : null,
      currentAffiliateId: (_, { affiliateId }) => affiliateId,
      behalfOf: ({ current: { behalfOf } }) => behalfOf,
      isAffiliateActive: ({ current: { affiliate, behalfOf } }) => behalfOf != null ? behalfOf.status !== 'VERIFIED' : affiliate != null && affiliate.status !== 'VERIFIED',
      config: ({ config }) => config,
      basePath: ({ config: { basePath } }) => basePath,
      iso: ({ global: { country: { iso } } }) => iso,
      applicationName: ({ config: { applicationName } }) => applicationName,
      global: ({ global }) => global,
      feature: ({ global: { feature } }) => feature,
      language: (_, { language }) => language,
      imageFormat: ({ config: { imageFormat } }) => imageFormat,
      assetService: ({ config: { assetService } }) => assetService,
      shopUrl: ({ global: { shop2Url }, current: { affiliate, behalfOf } }) => behalfOf != null ? `${shop2Url}/a/${behalfOf.id}` : `${shop2Url}/a/${affiliate.id}`,
      httpLoading: ({ loading }) => loading
    })
  }
}
