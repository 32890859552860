import log from 'loglevel'
import Vue from 'vue'
import App from './App.vue'
import store from './store/store'
import router from './router'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import { assetBasePath } from './config'
import Vuelidate from 'vuelidate'
import { i18n, sync } from './i18n'
import * as filters from './filters'
import Dates from './utils/dates'
import Plugin from './utils/plugin'
import VueMeta from 'vue-meta'
import cssVars from 'css-vars-ponyfill'
import IdleJs from 'idle-js'
import qs from 'qs'
import cookieconsent from './utils/cookieconsent'
import { get } from 'lodash-es'
import * as Sentry from "@sentry/vue"
import { BrowserTracing } from "@sentry/tracing"


import './inputs'

// eslint-disable-next-line
__webpack_public_path__ = assetBasePath
window.log = log

try {
  if (document.cookie && document.cookie.indexOf('utm-campaign') > -1 && document.cookie.indexOf('ü') > -1) {
    document.cookie = "utm-campaign=;path=/;domain=." + location.hostname.replace(/^www\./i, "") + ";expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
} catch (e) {
  log.error('campaign reset failed', e)
}

Vue.use(VueMeta, {})
Vue.use(Vuelidate)
Vue.use(Plugin)
Vue.use(Dates)

Vue.prototype.$http = axios

Vue.config.productionTip = false

axios.interceptors.request.use(config => {
  store.commit('loading', true)
  const [path, query] = config.url.split('?')
  const q = qs.parse(query)
  const { affiliateId } = store.getters
  if (affiliateId && !q.affiliateId) {
    q.affiliateId = affiliateId
    return { ...config, url: path + '?' + qs.stringify(q) }
  }
  return config
}, error => {
  store.commit('loading', false)
  return Promise.reject(error)
})

axios.interceptors.response.use(response => {
  store.commit('loading', false)
  return response
}, error => {
  store.commit('loading', false)

  if (error.response && error.response.status === 401 && store.getters.isAuthenticated) {
    store.dispatch('logout')
    if (store.state.ready) router.push('/logout?expired')
  }

  return Promise.reject(error)
})

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

sync(store, i18n)

if (!!window.MSInputMethodContext && !!document.documentMode) {
  cssVars({
    onlyLegacy: false,
    watch: true
  })
}

router.afterEach((to) => {
  store.dispatch('GTM_PUSH_VIEW', { pageTitle: document.title, pagePath: to.fullPath })
})

const start = () => {
  store.dispatch('GTM_PUSH_PAGE')

  const { dsn, web, ...rest } = get(store, 'state.global.sentry') || get(store, 'state.config.sentry')
  if (dsn && web === true)
    log.debug('sentry initialized', { dsn, web, ...rest })
    Sentry.init({
      Vue,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracePropagationTargets: [/^\//]
        }),
      ],
      dsn,
      ...rest
    })


  const app = new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
  }).$mount('#app')

  cookieconsent(app)

  setInterval(() => {
    store.dispatch('extend').catch(() => {})
  }, 5 * 60 * 1000)

  new IdleJs({
    idle: 15 * 60 * 1000,
    events: ['mousemove', 'keydown', 'mousedown', 'touchstart', 'scroll', 'touchmove'],
    onIdle: () => store.commit('setActive', false),
    onActive: () => store.commit('setActive', true),
    onHide: () => store.commit('setVisible', false),
    onShow: () => store.commit('setVisible', true),
    keepTracking: true,
    startAtIdle: false
  }).start()

  window.addEventListener('unhandledrejection', function ({ reason }) {
    store.commit('error', reason)
  })
}

store.dispatch('init').then(start, start)
