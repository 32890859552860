<template>
  <v-container v-if="busy" class="fill-height justify-center" fluid>
    <v-progress-circular :size="100" color="primary" class="align-self-center" indeterminate />
  </v-container>
  <v-container v-else>
    <div class="my-3">
      <h1 class="display-1 mb-5">
        {{ title }}
      </h1>
      <div class="static-page" v-html="html" />
      <div v-if="download" class="d-flex">
        <v-spacer />
        <v-btn :href="downloadUrl" target="_blank" color="primary" depressed>
          {{ $t('button.download') }}
        </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
import { apiPath } from '@/config'
import { mapState } from 'vuex'
import { kebabCase } from 'lodash-es'
import log from 'loglevel'
export default {
  name: 'Static',
  props: {
    page: {
      required: true
    },
    download: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      busy: false
    }
  },
  computed: mapState({
    language: (_, { language }) => language,
    html ({ page: { items } }, { language }) {
      return items[`${this.page}_${language}`]
    },
    title () {
      return this.$t(`page.${kebabCase(this.page)}`)
    },
    downloadUrl () {
      const { page, language } = this
      return `${apiPath}/legal/${page}?format=pdf&lang=${language}`
    }
  }),
  watch: {
    page () { this.load() },
    language () { this.load() }
  },
  created () {
    this.load()
  },
  methods: {
    load () {
      this.busy = true
      const { page, $store: { dispatch } } = this
      dispatch('findPage', { page })
        .then(() => {
          this.busy = false
          this.$nextTick(() => this.scrollTo())
        })
        .catch((error) => {
          log.error(error)
          this.$router.push('notFound')
        })
    },
    scrollTo () {
      const { path } = this.$route
      if (path === '/terms-and-conditions/fees') {
        const el = document.querySelector('#fee-overview')
        if (!el) return
        const { top } = el.getBoundingClientRect()
        window.scroll(0, window.pageYOffset + top)
      }
    }
  }
}
</script>

<style lang="scss">
.static-page ul,
.static-page ol,
.static-page .text-border {
  margin-bottom: 12px;
}

.static-page h4,
.static-page h5 {
  margin: 12px 0px;
}

.v-application .static-page a {
  font-weight: normal;
}

.static-page .agb-table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}

.static-page .agb-table tbody tr td:first-child {
  width: 60%;
}

.static-page .agb-table td {
  padding: 5px 10px 5px 10px;
  border: 1px solid #4C4C4C;
}

.privacypolicy {
  li {
    padding: 10px 0 10px 0;
  }
}

.static-page .cookie-table {
    border-collapse: collapse;
    table-layout: fixed;
}

.static-page .cookie-table th {
    background: lightgray;
    border: 1px solid #4C4C4C;
}

.static-page .cookie-table td {
    padding: 5px 10px 5px 10px;
    border: 1px solid #4C4C4C;
}

</style>
