import axios from 'axios'

export default {
  namespaced: false,
  actions: {
    findDashboardInfo: async ({ getters: { affiliateId }, rootState: { auth: { token } } }) => {
      const { data } = await axios.get('api/dashboard/' + affiliateId + '?token=' + token)
      return data
    }
  }
}
