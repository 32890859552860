export default {
  state: () => {
    return {
      unselectedHeaders: null,
      welcomeMarketingBannerClosed: false
    }
  },
  mutations: {
    setSettings: (state, data) => {
      Object.assign(state, data)
    }
  }
}
