import Vue from 'vue'
import Router from 'vue-router'
import { basePath } from './config'
import store from './store/store'
import { createStaticView } from '@/views/CreateStaticView'
import { createErrorView } from '@/views/CreateErrorView'
import { hasFeature, hasAffiliateFeature, hasMarketingHelp } from '@/utils/feature'
import { isAuthorizedForAffiliatePath, isAffiliateActivated } from '@/utils/auth'
import { isFunction, isArray } from 'lodash-es'
import BoAccessRights from '@/utils/bo-access-rights'

Vue.use(Router)

const staticPageRoutes = [
  { path: '/faq', name: 'faq' },
  { path: '/terms-and-conditions', alias: '/terms-and-conditions/fees', name: 'terms', download: true },
  { path: '/impressum', name: 'impressum' },
  { path: '/imprint', name: 'imprint' },
  { path: '/legal-notice', name: 'legalNotice' },
  { path: '/privacy-policy', name: 'privacy' }
].map(({ name, alias, path, download }) => {
  return {
    name,
    path,
    alias,
    meta: { requiresAuth: false },
    component: createStaticView({ page: name, download: download === true })
  }
})

const ifHasMarketingHelp = () => {
  return () => hasMarketingHelp()
}

const ifHasFeature = (feature) => {
  return () => hasFeature(feature)
}

const ifHasAnyFeature = (features) => {
  return () => features.some(hasFeature)
}

const ifHasNotAffiliateFeature = (feature) => {
  return () => !hasAffiliateFeature(feature)
}

const ifIsAuthorizedForAffiliate = (roles) => {
  return () => isAuthorizedForAffiliatePath([], roles)
}

const errorRoutes = [
  { path: '/access-denied', name: 'access-denied', error: { errorCode: '401' } },
  { path: '/not-found', alias: '*', name: 'not-found', error: { errorCode: '404' } }
].map(({ name, alias, path, error }) => ({ name, path, alias, meta: { requiresAuth: false }, component: createErrorView({ error }) }))

const skipIfNotRequired = (key) => {
  return (to, from, next) => {
    const { query: { redirect } = {} } = to
    const isRequired = store.getters[key]
    if (!isRequired) {
      next(redirect || '/')
    } else {
      next()
    }
  }
}

const router = new Router({
  mode: 'history',
  base: basePath,
  scrollBehavior (to) {
    if (to.hash && document.querySelector(to.hash)) {
      const el = document.querySelector(to.hash)
      const { top } = el.getBoundingClientRect()
      return { x: 0, y: window.pageYOffset + top }
    } else if (!to.hash) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ x: 0, y: 0 })
        }, 260)
      })
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    { path: '/', redirect: () => hasFeature('dashboard') ? '/dashboard' : '/events', meta: { requiresAuth: true }, if: ifHasFeature('dashboard') },

    { path: '/dashboard', name: 'dashboard', component: () => import(/* webpackChunkName: "dashboard" */'@/views/Dashboard.vue'), meta: { requiresAuth: true, if: [ifIsAuthorizedForAffiliate(['ADMIN', 'AFFILIATE_ADMIN', 'EVENT_VIEW', ...BoAccessRights.EVENT_VIEW]), ifHasFeature('dashboard')] } },

    // TODO: handle permissions on event / series level
    { path: '/events', name: 'events', component: () => import(/* webpackChunkName: "events" */'@/views/Events.vue'), meta: { requiresAuth: true, if: ifIsAuthorizedForAffiliate(['ADMIN', 'AFFILIATE_ADMIN', 'EVENT_VIEW', ...BoAccessRights.EVENT_VIEW]) } },
    { path: '/events/published', redirect: '/events' },
    { path: '/events/unpublished', redirect: '/events' },
    { path: '/events/expired', redirect: '/events' },
    { path: '/events/create', redirect: '/events/create/common' },
    { path: '/events/create/:step', name: 'events-create', component: () => import(/* webpackChunkName: "event-create" */'@/views/EventCreate.vue'), meta: { requiresAuth: true, if: ifIsAuthorizedForAffiliate(['ADMIN', 'AFFILIATE_ADMIN', 'EVENT_CREATE']) } },
    { path: '/events/:eventId/edit', redirect: '/events/:eventId/edit/common' },
    { path: '/events/:eventId/edit/:step', alias: '/event/:eventId/edit/:step', name: 'events-edit', component: () => import(/* webpackChunkName: "event-edit" */'@/views/EventEdit.vue'), meta: { requiresAuth: true, if: ifIsAuthorizedForAffiliate(['ADMIN', 'AFFILIATE_ADMIN', ...BoAccessRights.EVENT_VIEW]) } },
    { path: '/events/:eventId/copy', alias: '/event/:eventId/copy', name: 'events-copy', component: () => import(/* webpackChunkName: "copy" */'@/views/Copy.vue'), meta: { requiresAuth: true, if: ifIsAuthorizedForAffiliate(['ADMIN', 'AFFILIATE_ADMIN']) } },
    { path: '/events/:eventId/copy/publish', alias: '/event/:eventId/copy/publish', name: 'copy-publish', component: () => import(/* webpackChunkName: "review" */'@/views/Review.vue'), meta: { requiresAuth: true, if: ifIsAuthorizedForAffiliate(['ADMIN', 'AFFILIATE_ADMIN']) } },
    { path: '/events/:eventId/access', alias: '/event/:eventId/access', name: 'events-access', component: () => import(/* webpackChunkName: "access" */'@/views/Access.vue'), meta: { requiresAuth: true, if: ifIsAuthorizedForAffiliate(['ADMIN', 'AFFILIATE_ADMIN', 'EVENT_ACCESS_STATISTICS_VIEW']) } },
    { path: '/events/:eventId/review', alias: '/event/:eventId/review', name: 'events-review', component: () => import(/* webpackChunkName: "review" */'@/views/Review.vue'), meta: { requiresAuth: true } },
    { path: '/events/:eventId/facebook', alias: '/event/:eventId/facebook', name: 'events-facebook', component: () => import(/* webpackChunkName: "facebook" */'@/views/Facebook.vue'), meta: { requiresAuth: true, if: [ifHasFeature('facebook'), ifIsAuthorizedForAffiliate(['ADMIN', 'AFFILIATE_ADMIN'])] } },
    { path: '/events/:eventId/preview/ticket', alias: '/event/:eventId/preview/ticket', name: 'events-preview-ticket', component: () => import(/* webpackChunkName: "preview-ticket" */'@/views/PreviewTicket.vue'), meta: { requiresAuth: true } },
    {
      path: '/events/:eventId',
      alias: '/event/:eventId',
      name: 'event',
      component: () => import(/* webpackChunkName: "event" */'@/views/Event.vue'),
      meta: { requiresAuth: true },
      children: [
        { path: 'preview/shop', name: 'events-preview-shop', component: () => import(/* webpackChunkName: "preview-shop" */'@/views/PreviewShop.vue') }
      ],
    },

    { path: '/series', name: 'series', component: () => import(/* webpackChunkName: "series" */'@/views/Series.vue'), meta: { requiresAuth: true, if: ifIsAuthorizedForAffiliate(['ADMIN', 'AFFILIATE_ADMIN', 'EVENT_VIEW', ...BoAccessRights.SERIES_VIEW]) } },
    { path: '/series/create', name: 'series-create', component: () => import(/* webpackChunkName: "serie-create" */'@/views/SerieCreate.vue'), meta: { requiresAuth: true, if: ifIsAuthorizedForAffiliate(['ADMIN', 'AFFILIATE_ADMIN', 'EVENT_CREATE']) } },
    { path: '/series/:seriesId', name: 'series-view', component: () => import(/* webpackChunkName: "serie" */'@/views/Serie.vue'), meta: { requiresAuth: true, if: ifIsAuthorizedForAffiliate(['ADMIN', 'AFFILIATE_ADMIN', 'EVENT_VIEW', ...BoAccessRights.SERIES_VIEW]) } },
    { path: '/series/:seriesId/edit', name: 'series-edit', component: () => import(/* webpackChunkName: "serie-edit" */'@/views/SerieEdit.vue'), meta: { requiresAuth: true, if: ifIsAuthorizedForAffiliate(['ADMIN', 'AFFILIATE_ADMIN', ...BoAccessRights.SERIES_EDIT]) } },
    { path: '/series/:seriesId/timeslots', name: 'series-timeslots', component: () => import(/* webpackChunkName: "serie-timeslots" */'@/views/SerieTimeslots.vue'), meta: { requiresAuth: true, if: ifIsAuthorizedForAffiliate(['ADMIN', 'AFFILIATE_ADMIN', ...BoAccessRights.SERIES_EDIT]) } },

    { path: '/promotions', name: 'promotions', redirect: { path: '/promotions/active' } },
    { path: '/promotions/active', name: 'promotions-active', component: () => import(/* webpackChunkName: "promotions" */'@/views/Promotions.vue'), meta: { requiresAuth: true, if: [ifIsAuthorizedForAffiliate(['ADMIN', 'AFFILIATE_ADMIN', ...BoAccessRights.PROMOTIONS]), isAffiliateActivated, ifHasNotAffiliateFeature('noPromotions')] } },
    { path: '/promotions/inactive', name: 'promotions-inactive', component: () => import(/* webpackChunkName: "promotions" */'@/views/Promotions.vue'), meta: { requiresAuth: true, if: [ifIsAuthorizedForAffiliate(['ADMIN', 'AFFILIATE_ADMIN', ...BoAccessRights.PROMOTIONS]), isAffiliateActivated, ifHasNotAffiliateFeature('noPromotions')] } },
    { path: '/promotions/create', name: 'promotion-create', component: () => import(/* webpackChunkName: "promotion-create" */'@/views/PromotionCreate.vue'), meta: { requiresAuth: true, if: [ifIsAuthorizedForAffiliate(['ADMIN', 'AFFILIATE_ADMIN']), isAffiliateActivated, ifHasNotAffiliateFeature('noPromotions')] } },
    { path: '/promotions/:promotionId', name: 'promotion', component: () => import(/* webpackChunkName: "promotion" */'@/views/Promotion.vue'), meta: { requiresAuth: true, if: [ifIsAuthorizedForAffiliate(['ADMIN', 'AFFILIATE_ADMIN', ...BoAccessRights.PROMOTIONS]), isAffiliateActivated, ifHasNotAffiliateFeature('noPromotions')] } },
    { path: '/promotions/:promotionId/edit', name: 'promotion-edit', component: () => import(/* webpackChunkName: "promotion-edit" */'@/views/PromotionEdit.vue'), meta: { requiresAuth: true, if: [ifIsAuthorizedForAffiliate(['ADMIN', 'AFFILIATE_ADMIN', ...BoAccessRights.PROMOTIONS_EDIT]), isAffiliateActivated, ifHasNotAffiliateFeature('noPromotions')] } },

    {
      path: '/access',
      name: 'access',
      component: () => import(/* webpackChunkName: "access" */'@/views/Access.vue'),
      redirect: '/access/start',
      meta: { requiresAuth: true },
      children: [
        { path: '/access/start', name: 'access-start', component: () => import(/* webpackChunkName: "access-start" */'@/views/AccessStart.vue'), meta: { requiresAuth: true } },
        { path: '/access/config', name: 'access-config', component: () => import(/* webpackChunkName: "access-config" */'@/views/AccessConfig.vue'), meta: { requiresAuth: true, if: ifIsAuthorizedForAffiliate(['ADMIN', 'AFFILIATE_ADMIN', 'EVENT_ACCESS_CODES_DOWNLOAD', ...BoAccessRights.EVENT_REPORTS]) } },
        { path: '/access/clearing', name: 'access-clearing', component: () => import(/* webpackChunkName: "access-clearing" */'@/views/AccessClearing.vue'), meta: { requiresAuth: true, if: ifIsAuthorizedForAffiliate(['ADMIN', 'AFFILIATE_ADMIN', 'EVENT_CLEARING_VIEW', 'EVENT_ACCESS_STATISTICS_VIEW', ...BoAccessRights.EVENT_REPORTS]) } },
        { path: '/access/clearing/:id', name: 'access-clearing-event', component: () => import(/* webpackChunkName: "access-clearing" */'@/views/AccessClearing.vue'), meta: { requiresAuth: true, if: ifIsAuthorizedForAffiliate(['ADMIN', 'AFFILIATE_ADMIN', 'EVENT_CLEARING_VIEW', 'EVENT_ACCESS_STATISTICS_VIEW', ...BoAccessRights.EVENT_REPORTS]) } }
      ]
    },
    { path: '/reports', name: 'reports', component: () => import(/* webpackChunkName: "reports" */'@/views/Reports.vue'), meta: { requiresAuth: true, if: ifIsAuthorizedForAffiliate(['ADMIN', 'AFFILIATE_ADMIN', 'AFFILIATE_REPORT_VIEW']) } },
    {
      path: '/shop-config',
      name: 'shop',
      component: () => import(/* webpackChunkName: "shop" */'@/views/ShopConfig.vue'),
      redirect: '/shop-config/integration',
      meta: { requiresAuth: true },
      children: [
        { path: '/shop-config/integration', name: 'shop-config-integration', component: () => import(/* webpackChunkName: "shop-config-integration" */'@/views/ShopIntegration.vue'), meta: { requiresAuth: true } },
        { path: '/shop-config/analytics', name: 'shop-config-analytics', component: () => import(/* webpackChunkName: "shop-config-analytics" */'@/views/ShopAnalytics.vue'), meta: { requiresAuth: true } },
        { path: '/shop-config/layout', name: 'shop-config-layout', component: () => import(/* webpackChunkName: "shop-config-layout" */'@/views/ShopLayout.vue'), meta: { requiresAuth: true, if: ifIsAuthorizedForAffiliate(['ADMIN', 'AFFILIATE_ADMIN', 'SHOP_CONFIG_EDIT', ...BoAccessRights.AFFILIATE_VIEW]) } },
        { path: '/shop-config/questions', name: 'shop-config-questions', component: () => import(/* webpackChunkName: "shop-config-questions" */'@/views/ShopQuestions.vue'), meta: { requiresAuth: true, if: ifIsAuthorizedForAffiliate(['ADMIN', 'AFFILIATE_ADMIN', 'SHOP_CONFIG_EDIT', ...BoAccessRights.AFFILIATE_VIEW]) } },
        { path: '/shop-config/tracking', name: 'shop-config-tracking', component: () => import(/* webpackChunkName: "shop-config-tracking" */'@/views/ShopTracking.vue'), meta: { requiresAuth: true, if: [ifHasAnyFeature(['robotsIndex', 'googleTracking']), ifIsAuthorizedForAffiliate(['ADMIN', 'AFFILIATE_ADMIN', 'SHOP_CONFIG_EDIT', ...BoAccessRights.AFFILIATE_VIEW])] } }
      ]
    },
    { path: '/user/settings', name: 'settings', component: () => import(/* webpackChunkName: "settings" */'@/views/Settings.vue'), meta: { requiresAuth: true } },
    { path: '/affiliate/collective-settlement', name: 'collective-settlement', component: () => import(/* webpackChunkName: "collective-settlement" */'@/views/CollectiveSettlement.vue'), meta: { requiresAuth: true, if: [ifHasFeature('collectiveSettlements'), ifIsAuthorizedForAffiliate(['ADMIN', 'AFFILIATE_ADMIN'])] } },
    { path: '/affiliate/additional-agreements', name: 'additional-agreements', component: () => import(/* webpackChunkName: "additional-agreements" */'@/views/AdditionalAgreements.vue'), meta: { requiresAuth: true, if: [ifHasFeature('additionalAgreement'), ifIsAuthorizedForAffiliate(['ADMIN', 'AFFILIATE_ADMIN'])] } },
    { path: '/contact', name: 'contact', component: () => import(/* webpackChunkName: "contact" */'@/views/Contact.vue'), meta: { requiresAuth: false } },
    { path: '/help', name: 'help', component: () => import(/* webpackChunkName: "help" */'@/views/Help.vue'), meta: { requiresAuth: true, if: ifHasMarketingHelp() } },

    {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: "login" */'@/views/Login.vue'),
      meta: { requiresAuth: false },
      children: [
        { path: 'forgot-password', component: () => import(/* webpackChunkName: "forget-password-dialog" */'@/views/ForgetPasswordDialog.vue') },
        { path: 'reset-password/:code', component: () => import(/* webpackChunkName: "reset-password-dialog" */'@/views/ResetPasswordDialog.vue') }
      ]
    },

    { path: '/reset-password/:code', redirect: '/login/reset-password/:code' },

    { path: '/logout', name: 'logout', component: () => import(/* webpackChunkName: "logout" */'@/views/Logout.vue'), meta: { requiresAuth: false } },
    { path: '/register', name: 'register', component: () => import(/* webpackChunkName: "registration" */'@/views/Registration.vue'), meta: { requiresAuth: false, if: ifHasFeature('register') } },
    { path: '/activate/:code', name: 'activate', component: () => import(/* webpackChunkName: "activation" */'@/views/Activation.vue'), meta: { requiresAuth: false } },
    { path: '/account/terms', name: 'account-terms', component: () => import(/* webpackChunkName: "account-terms" */'@/views/AccountTerms.vue'), meta: { requiresAuth: true, alwaysAccessible: true }, beforeEnter: skipIfNotRequired('requiresTermsAcceptance') },
    { path: '/account/sepa', name: 'account-sepa', component: () => import(/* webpackChunkName: "account-sepa" */'@/views/AccountSepa.vue'), meta: { requiresAuth: true, alwaysAccessible: true }, beforeEnter: skipIfNotRequired('requiresSepa') },

    ...staticPageRoutes,
    ...errorRoutes
  ]
})

router.beforeEach((to, from, next) => {
  if (shouldRedirectToV3(to)) {
    redirectToV3(to);
    return next(false);
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters.isAuthenticated) {
      next({ path: '/login', query: { redirect: to.fullPath, ...to.query } })
    } else if (to.meta.if != null && ((isFunction(to.meta.if) && !to.meta.if()) || (isArray(to.meta.if) && to.meta.if.some(fn => !fn())))) {
      next({ path: '/access-denied', query: { redirect: to.fullPath } })
    } else if (store.getters.requiresTermsAcceptance && !to.meta.alwaysAccessible) {
      next({ path: '/account/terms', query: { redirect: to.fullPath } })
    } else if (store.getters.requiresSepa && !to.meta.alwaysAccessible) {
      next({ path: '/account/sepa', query: { redirect: to.fullPath } })
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.if != null)) {
    if (to.meta.if != null && ((isFunction(to.meta.if) && !to.meta.if()) || (isArray(to.meta.if) && to.meta.if.some(fn => !fn())))) {
      next({ path: '/access-denied', query: { redirect: to.fullPath } })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

function shouldRedirectToV3 (to) {
  const bundleInPath = to.path.includes('bundle');
  const bundleInQuery = to.query.redirect && to.query.redirect.includes('bundle');
  // console.log('shouldRedirectToV3', bundleInPath || bundleInQuery, to)
  return bundleInPath || bundleInQuery
}

function redirectToV3 (to) {
  // let newLocation = `${window.location.origin}${basePath}${to.path}`.replace('8280','8290') // for local debugging of redirects
  let newLocation = `${window.location.origin}${basePath}${to.path}`

  const queryParams = new URLSearchParams(to.query).toString()

  if (queryParams) {
    newLocation += '?' + queryParams;
  }

  return window.location.assign(newLocation)
}

export default router
