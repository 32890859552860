import { union } from 'lodash-es'
const acl = {}

// following rules should be stored on root access path - not affiliate path
acl.ROOT_ROLES = ['USER', 'ADMIN', 'ACCOUNTANT', 'MARKETING', 'SALES', 'SUPPORT', 'VENUE', 'SUPPORT CONSUMER', 'SUPPORT OUTLETS', 'SERVICE', 'SALESFORCE']

acl.AFFILIATE_VIEW = ['ACCOUNTANT', 'ADMIN', 'SALES', 'SUPPORT', 'VENUE']
acl.AFFILIATE_CREATE = ['ADMIN', 'SALES']
acl.AFFILIATE_EDIT = ['ADMIN', 'SALES', 'SUPPORT']
acl.AFFILIATE_STATUS = ['ADMIN', 'SALES']
acl.AFFILIATE_SCANAPP_CODES = ['ADMIN', 'SUPPORT', 'SALES']
acl.AFFILIATE_SHOP_CONFIG = ['ADMIN', 'SALES', 'SUPPORT']
acl.AFFILIATE_EDIT_BANK_DETAILS = ['ADMIN']
acl.AFFILIATE_EDIT_FEES = ['ADMIN']
acl.AFFILIATE_EDIT_ADVANCE_PAYMENT_RATE = ['ADMIN']

acl.USER_VIEW = ['ADMIN', 'SALES', 'SUPPORT']
acl.USER_CREATE = ['ADMIN']
acl.USER_DELETE = ['ADMIN', 'SALES']
acl.USER_ROLE = ['ADMIN']
acl.USER_EDIT = ['ADMIN', 'SALES', 'SUPPORT']
acl.USER_STATUS = ['ADMIN', 'SALES', 'SUPPORT']

acl.EVENT_VIEW = ['ADMIN', 'ACCOUNTANT', 'SALES', 'SUPPORT', 'VENUE', 'SUPPORT CONSUMER', 'SUPPORT OUTLETS']
acl.EVENT_EDIT = ['ADMIN', 'SUPPORT', 'VENUE']
acl.EVENT_EDIT_PRICES = ['ADMIN', 'SUPPORT', 'ACCOUNTANT']
acl.EVENT_DL_ACCESS_LIST = ['ADMIN', 'SUPPORT', 'SALES']
acl.EVENT_STATUS = ['ACCOUNTANT', 'ADMIN', 'SUPPORT']
acl.EVENT_REPORTS = ['ACCOUNTANT', 'ADMIN', 'SALES', 'SUPPORT']
acl.EVENT_SCANAPP_CODES = ['ADMIN', 'SUPPORT']
acl.EVENT_ADVANCE_PAYMENT = ['ADMIN']
acl.EVENT_DL_SETTLEMENT = ['ADMIN', 'SUPPORT', 'SALES', 'ACCOUNTANT']
acl.EVENT_MASS_CANCELLATION = ['ADMIN']

acl.VENUE_VIEW = ['ADMIN', 'SALES', 'SUPPORT', 'VENUE']
acl.VENUE_CREATE = ['ADMIN', 'VENUE']
acl.VENUE_EDIT = ['ADMIN', 'VENUE']
acl.VENUE_STATUS = ['ADMIN', 'VENUE']

acl.SETTLEMENT_VIEW = ['ACCOUNTANT', 'ADMIN']
acl.SETTLEMENT_DOWNLOAD = ['ACCOUNTANT', 'ADMIN']
acl.SETTLEMENT_TURNOVER_VIEW = ['ACCOUNTANT', 'ADMIN']

acl.SERIES_VIEW = ['ADMIN', 'ACCOUNTANT', 'SALES', 'SUPPORT', 'VENUE', 'SUPPORT CONSUMER', 'SUPPORT OUTLETS']
acl.SERIES_EDIT = ['ADMIN', 'SUPPORT', 'VENUE']
acl.SERIES_ADVANCE_PAYMENT = ['ADMIN']

acl.REPORT_VIEW = ['ADMIN', 'SALES', 'ACCOUNTANT']
acl.KPS_TRANSACTIONS_VIEW = ['ADMIN', 'ACCOUNTANT']

acl.TOOLS = ['ADMIN', 'SUPPORT', 'SALES']
acl.PROMOTIONS = ['ADMIN', 'SUPPORT', 'SALES']
acl.PROMOTIONS_EDIT = ['ADMIN']

acl.NEWSBANNER_VIEW = ['ADMIN', 'MARKETING']
acl.NEWSBANNER_CREATE = ['ADMIN', 'MARKETING']
acl.NEWSBANNER_EDIT = ['ADMIN', 'MARKETING']
acl.NEWSBANNER_STATUS = ['ADMIN', 'MARKETING']

// TIXX BO MENU ITEMS
acl.ACCESS_CONTROL_VIEW = ['ADMIN']
acl.CUSTOMERS_VIEW = ['ADMIN', 'SUPPORT CONSUMER']
acl.ORDERS_VIEW = ['ADMIN', 'SUPPORT CONSUMER']
acl.CANCELLATION_VIEW = ['ADMIN', 'SUPPORT CONSUMER']
acl.OUTLETS_REPRINT_VIEW = ['ADMIN', 'SUPPORT OUTLETS']
acl.OUTLETS_CANCELLATION_VIEW = ['ADMIN', 'SUPPORT OUTLETS']
acl.STATISTICS_GENERAL_VIEW = ['ADMIN']
acl.STATISTICS_EVENT_VIEW = ['ADMIN', 'SUPPORT CONSUMER']
acl.RESERVATIONS_VIEW = ['ADMIN']
acl.PAYMENT_METHODS_VIEW = ['ADMIN']
acl.IMPRINT_VIEW = ['ADMIN']
acl.STATS_CONTROLLING_VIEW = ['ADMIN', 'ACCOUNTANT']
acl.SALES_REPORT = ['ADMIN', 'SALES', 'ACCOUNTANT']

// BO SIDEBAR GROUPS
acl.GROUP_ADMIN_VIEW = ['ADMIN']

acl.GROUP_AFFILIATES_VIEW = union(acl.AFFILIATE_VIEW, acl.USER_VIEW)
acl.GROUP_EVENTS_VIEW = union(acl.EVENT_VIEW, acl.SERIES_VIEW, acl.VENUE_VIEW)
acl.GROUP_REPORTS_VIEW = union(acl.REPORT_VIEW, acl.STATS_CONTROLLING_VIEW, acl.SALES_REPORT, acl.KPS_TRANSACTIONS_VIEW)
acl.GROUP_ACCOUNTING_VIEW = union(acl.SETTLEMENT_VIEW, acl.SETTLEMENT_TURNOVER_VIEW)
acl.GROUP_MARKETING_VIEW = union(acl.NEWSBANNER_VIEW, acl.NEWSBANNER_EDIT)

export default acl
