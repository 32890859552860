import Vue from 'vue'
import axios from 'axios'
import { apiPath } from '@/config'

export default {
  namespaced: false,
  state: () => {
    return {
      items: {}
    }
  },
  mutations: {
    findReviewEventResult: (state, { items, id }) => {
      Vue.set(state.items, id, items)
    }
  },
  getters: {},
  actions: {
    findReviewEvent: ({ commit, dispatch }, { id }) => {
      return new Promise((resolve, reject) => {
        axios.get(`${apiPath}/event/${id}/review`)
          .then(({ data, headers }) => {
            const { eventSeriesId } = data
            const promises = []
            if (eventSeriesId) promises.push(dispatch('findSerie', { id: eventSeriesId, lazy: true }))
            Promise.all(promises).then(() => {
              data.checksum = headers['x-md5-checksum']
              commit('findReviewEventResult', { items: data, id })
              resolve(data)
            }).catch(e => reject(e))
          })
          .catch(error => {
            throw error
          })
      })
    }
  }
}
