import axios from 'axios'
import { apiPath } from './../../config'

export default {
  namespaced: false,
  state: () => {
    return {
      customData: {}
    }
  },
  mutations: {
    customData_success: (state, customData) => {
      state.customData = customData
    }
  },
  getters: {
  },
  actions: {
    customData: ({ commit, getters: { isAuthenticated, userId } }, customData) => {
      if (!isAuthenticated) throw new Error('Not authenticated')
      return axios.patch(`${apiPath}/user/${userId}/customData`, customData)
        .then(function ({ data: customData }) {
          commit('customData_success', customData)
        })
        .catch(function (error) {
          throw error
        })
    },
    saveBankAccount: ({ getters: { affiliateId } }, { holder, iban, bic, domesticAccountNumber, domesticSortCode, bankName, type }) => {
      return axios.put(`${apiPath}/affiliate/${affiliateId}/bankAccount`, { holder, iban, bic, domesticAccountNumber, domesticSortCode, bankName, type })
    },
    changePromotionType: ({ getters: { affiliateId } }, { promotersType, licenseNumbers }) => {
      return axios.patch(`${apiPath}/affiliate/${affiliateId}`, { promotersType, licenseNumbers })
    },
    patchCurrentAffiliate: ({ getters: { affiliateId } }, { locale }) => {
      return axios.patch(`${apiPath}/affiliate/${affiliateId}`, { locale })
    }
  }
}
