import axios from 'axios'
import { apiPath } from './../../config'
import QS from 'qs'

export default {
  namespaced: false,
  actions: {
    findFee: ({ getters: { affiliateId } }, { categoryId }) => {
      const query = QS.stringify({ categoryId, affiliateId })
      return new Promise((resolve, reject) => {
        axios.get(`${apiPath}/price/fee?${query}`)
          .then(({ data }) => { resolve(data) })
          .catch((error) => reject(error))
      })
    }
  }
}
