import Vue from 'vue'
import axios from 'axios'
import { apiPath } from '@/config'
import QS from 'qs'

const serializeOrigin = (src) => {
  if (src == null) return src
  const { id, coordinates } = src
  const query = QS.stringify(coordinates)
  return `${id}?${query}`
}

const deserizalizeOrigin = (str) => {
  if (str == null) return str
  const [id, query] = str.split('?')
  const coordinates = QS.parse(query)
  return { id, coordinates }
}

export default {
  namespaced: false,
  state: () => {
    return {
      items: {}
    }
  },
  actions: {
    uploadAsset: ({ getters: { affiliateId } }, { name, copyright, origin, mimeType, content, domainId }) => {
      return new Promise((resolve, reject) => {
        axios.post(`${apiPath}/asset/upload`, { name, copyright, origin: serializeOrigin(origin), mimeType, content, affiliateId, domainId })
          .then(({ data }) => { resolve(data) })
          .catch((error) => reject(error))
      })
    },
    findAsset: async ({ commit, state: { items } }, { id }) => {
      if (items[id] != null) return Promise.resolve(items[id])
      const { data } = await axios.get(`${apiPath}/asset/${id}`)
      data.origin = deserizalizeOrigin(data.origin)
      commit('findAsset', { item: data })
      return data
    }
  },
  mutations: {
    findAsset: (state, { item }) => {
      Vue.set(state.items, item.id, item)
    }
  }
}
