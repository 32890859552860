import Vue from 'vue'
import axios from 'axios'
import { apiPath } from '@/config'
import qs from 'qs'
import { sortBy } from 'lodash-es'

export default {
  namespaced: false,
  state: () => {
    return {
      clearing: {},
      overview: {},
      timeline: {}
    }
  },
  mutations: {
    findClearingResult: (state, { data, id }) => {
      Vue.set(state.clearing, id, data)
    },
    findTimelineResult: (state, { data, id }) => {
      Vue.set(state.timeline, id, data)
    },
    findOverviewResult: (state, { data, id }) => {
      Vue.set(state.overview, id, data)
    }
  },
  getters: {},
  actions: {
    findClearing: ({ commit }, { id, barcode, lastName, ticketAreas }) => {
      const query = qs.stringify({ barcode, lastName, ticketAreas })
      return axios.get(`${apiPath}/event/${id}/clearing?${query}`)
        .then(({ data }) => {
          commit('findClearingResult', { data, id })
        })
        .catch((error) => {
          throw error
        })
    },
    findTimeline: ({ commit }, { id }) => {
      return axios.get(`${apiPath}/event/${id}/report/access/timeline`)
        .then(({ data }) => {
          data = sortBy(data, 'time')
          commit('findTimelineResult', { data, id })
        })
        .catch((error) => {
          throw error
        })
    },
    findOverview: ({ commit }, { id }) => {
      return axios.get(`${apiPath}/event/${id}/report/access/overview`)
        .then(({ data }) => {
          commit('findOverviewResult', { data, id })
        })
        .catch((error) => {
          throw error
        })
    }
  }
}
