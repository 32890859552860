import Vue from 'vue'
import VueI18n from 'vue-i18n'
import moment from 'moment'

Vue.use(VueI18n)

const messages = {
  en: { message: { hello: 'mep' } }
}

const i18n = new VueI18n({
  locale: 'en',
  messages,
  silentTranslationWarn: true
})

const internalTranslate = i18n._translate
i18n._translate = function (a, b, c, rawKey, ...rest) {
  const profileKey = window.profile || 'init'
  const customKey = rawKey.indexOf('custom.') === 0 ? rawKey : `custom.${rawKey}`
  const key = [`${profileKey}.${customKey}`, customKey].find(key => i18n.te.call(this, key)) || rawKey
  return internalTranslate.call(i18n, a, b, c, key, ...rest)
}

function sync (store, i18n) {
  const setLang = (lang) => {
    i18n.locale = lang
    moment.locale(lang)
  }

  const update = () => {
    setLang(store.getters.language)
  }

  store.subscribe((mutation, state) => {
    if (mutation.type === 'setLanguageBundle') {
      i18n.setLocaleMessage(mutation.payload.language, state.languages[mutation.payload.language])
      update()
    } else if (mutation.type === 'setLanguage') {
      setLang(mutation.payload.language)
    }
  })

  store.watch(
    function (_, { userLanguage }) {
      return userLanguage
    },
    (language) => {
      if (language != null) store.dispatch('setLanguage', { language })
    }
  )

  update()
}

export { i18n, sync }
