import Vue from 'vue'
import axios from 'axios'
import { apiPath } from '../../config'

export default {
  namespaced: false,
  state: () => {
    return {
      items: {}
    }
  },
  mutations: {
    findSerieResult: (state, { item }) => {
      Vue.set(state.items, item.id, item)
    },
    deleteSerieResult: (state, { item }) => {
      Vue.delete(state.items, item.id)
    }
  },
  getters: {
  },
  actions: {
    findSerie: ({ commit, state: { items } }, { id, lazy }) => {
      if (lazy && items[id] != null) return Promise.resolve(items[id])
      return new Promise((resolve, reject) => {
        return axios.get(`${apiPath}/eventSeries/${id}`)
          .then(({ data }) => {
            commit('findSerieResult', { item: data })
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    createSerie: ({ commit }, { item }) => {
      return new Promise((resolve, reject) => {
        axios.post(`${apiPath}/eventSeries`, item).then(({ data }) => {
          commit('findSerieResult', { item: data })
          resolve(data)
        }).catch(e => reject(e))
      })
    },
    updateSerie: ({ commit }, { item }) => {
      const { id } = item
      return new Promise((resolve, reject) => {
        axios.put(`${apiPath}/eventSeries/${id}`, item).then(({ data }) => {
          commit('findSerieResult', { item: data })
          resolve(data)
        }).catch(e => reject(e))
      })
    },
    deleteSerie: ({ commit }, { id }) => {
      return axios.delete(`${apiPath}/eventSeries/${id}`)
        .then(function ({ data }) {
          commit('deleteSerieResult', { item: data })
        })
        .catch(function (error) {
          throw error
        })
    }
  }
}
