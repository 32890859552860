import Vue from 'vue'
import axios from 'axios'
import { apiPath } from '@/config'
import qs from 'qs'

function prepare (data) {
  if (data == null) return null
  const indexStart = data.indexOf('<body>')
  const indexEnd = data.indexOf('</body>')
  return indexStart !== -1 && indexEnd !== -1 ? data.substring(indexStart + 6, indexEnd) : data
}

export default {
  namespaced: false,
  state: () => {
    return {
      items: {}
    }
  },
  mutations: {
    setPage: (state, { page, lang, data }) => {
      Vue.set(state.items, `${page}_${lang}`, data)
    }
  },
  actions: {
    findPage: ({ commit, getters: { language: lang } }, { page }) => {
      return new Promise((resolve, reject) => {
        const query = qs.stringify({ format: 'html', lang })
        axios.get(`${apiPath}/legal/${page}?${query}`)
          .then(({ data }) => {
            data = prepare(data)
            commit('setPage', { page, lang, data })
            resolve(data)
          })
          .catch((error) => reject(error))
      })
    }
  }
}
