<template>
  <v-container class="fill-height justify-center text-center">
    <div>
      <v-img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ny41IDQ3LjUiPjxkZWZzPjxjbGlwUGF0aCBpZD0iYSI+PHBhdGggZD0iTTAgMzhoMzhWMEgwdjM4eiIvPjwvY2xpcFBhdGg+PC9kZWZzPjxnIGNsaXAtcGF0aD0idXJsKCNhKSIgdHJhbnNmb3JtPSJtYXRyaXgoMS4yNSAwIDAgLTEuMjUgMCA0Ny41KSI+PHBhdGggZD0iTTM2IDE5YzAtOS4zODktNy42MTEtMTctMTctMTdTMiA5LjYxMSAyIDE5czcuNjExIDE3IDE3IDE3IDE3LTcuNjExIDE3LTE3IiBmaWxsPSIjZmZjYzRkIi8+PHBhdGggZD0iTTE1IDIzYzAtMS42NTctLjk2LTMtMi4xNDMtMy0xLjE4NCAwLTIuMTQzIDEuMzQzLTIuMTQzIDNzLjk1OSAzIDIuMTQzIDNDMTQuMDQgMjYgMTUgMjQuNjU3IDE1IDIzTTI3IDIzLjJjMC0xLjU0Ny0uODk1LTIuOC0yLTIuOC0xLjEwNCAwLTIgMS4yNTMtMiAyLjggMCAxLjU0Ni44OTYgMi44IDIgMi44IDEuMTA1IDAgMi0xLjI1NCAyLTIuOE0yMyAxMmMwLTIuNzYzLTEuNzkyLTUtNC01LTIuMjEgMC00IDIuMjM3LTQgNSAwIDIuNzYxIDEuNzkgNSA0IDUgMi4yMDggMCA0LTIuMjM5IDQtNSIgZmlsbD0iIzY2NDUwMCIvPjwvZz48L3N2Zz4=" class="page-error__icon" contain :aspect-ratio="1" :max-height="200" />
      <h1>{{ $t('not-available.title') }}</h1>
      <p>{{ $t('not-available.text') }}</p>
    </div>
  </v-container>
</template>

<script>
import Base from '@/mixins/base'
export default {
  name: 'Shop',
  components: {
  },
  mixins: [Base]
}
</script>
