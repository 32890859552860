import Vue from 'vue'
import axios from 'axios'
import { apiPath } from '@/config'

export default {
  namespaced: false,
  state: () => {
    return {
      items: {},
      venues: {}
    }
  },
  mutations: {
    updateSesamItem: (state, { item }) => {
      if (state.items[item.id]) {
        Object.assign(state.items[item.id], item)
      } else {
        Vue.set(state.items, item.id, item)
      }
    },
    updateSesamItemsForVenue (state, { venueId, items }) {
      items.forEach(item => this.commit('updateSesamItem', { item }))
      Vue.set(state.venues, venueId, items.map(({ id }) => state.items[id]))
    }
  },
  actions: {
    createSesam: async ({ state, commit }, { venueId, name }) => {
      const { data } = await axios.post(`${apiPath}/venue/${venueId}/sesam`, { name })
      commit('updateSesamItem', { item: data })
      return state.items[data.id]
    },
    copySesam: async ({ state, commit }, { venueId, name, sesamKey, sesamVersion }) => {
      const { data } = await axios.post(`${apiPath}/venue/${venueId}/sesam/${sesamKey}/${sesamVersion}`, { name })
      commit('updateSesamItem', { item: data })
      return state.items[data.id]
    },
    findAndUpdateSesam: async ({ state, commit }, { venueId, sesamId }) => {
      const { data } = await axios.get(`${apiPath}/venue/${venueId}/sesam/${sesamId}`)
      commit('updateSesamItem', { item: data })
      return state.items[data.id]
    },
    findSesamsForVenue: async ({ state, commit, getters: { affiliateId } }, { venueId }) => {
      const { data } = await axios.get(`${apiPath}/venue/${venueId}/sesam?affiliateId=${affiliateId}&limit=9999`)
      commit('updateSesamItemsForVenue', { venueId, items: data })
      return state.venues[venueId]
    },
    importSesam: async ({ state, commit, getters: { affiliateId }  }, { name, mimeType, content, venueId, charset }) => {
      const { data } = await axios.post(`${apiPath}/venue/${venueId}/sesam/import`, { name, mimeType, content, venueId, affiliateId, charset })
      commit('updateSesamItem', { item: data })
      return state.items[data.id]
    }
  }
}
