import store from '@/store/store'
import { get, reduce, intersection, isEmpty } from 'lodash-es'
import log from 'loglevel'

export function isSubPath (path, child) {
  if (path == null || child == null) return false
  if (path.length > child.length) return false
  if (path.length === 0) return true
  for (let i = 0; i < path.length; i++) {
    if (path[i] !== child[i]) return false
  }
  return true
}

export function isAuthorizedForPath (pathToCheck, rolesToCheck) {
  const accessPaths = get(store, 'state.current.user.accessPaths')
  if (accessPaths == null) return false
  if (pathToCheck == null) return false
  if (isEmpty(rolesToCheck)) return false
  const granted = reduce(accessPaths, function (memo, { path, roles }) {
    if (memo) return memo
    return isSubPath(path, pathToCheck) && intersection(roles, rolesToCheck).length > 0
  }, false)
  if (!granted) log.debug(`Permission ${granted ? 'granted' : 'denied'} - roles: ${rolesToCheck.join(', ')} @ path: ${pathToCheck.join('/')}`)
  return granted
}

export function isAuthorizedForAffiliatePath (path, rolesToCheck) {
  const affiliateId = get(store, 'getters.affiliateId')
  if (!affiliateId) return false
  return isAuthorizedForPath([affiliateId, ...path], rolesToCheck)
}

export function hasPermissionForEvent (event, roles) {
  if (event == null) return false
  const { id: eventId, eventSeriesId } = event
  if (event.id == null) return false
  const hasAccessToEvent = isAuthorizedForAffiliatePath([eventId], roles)
  const hasAccessToSeriesEvent = eventSeriesId != null && isAuthorizedForAffiliatePath([eventSeriesId, 's', eventId], roles)
  return hasAccessToEvent || hasAccessToSeriesEvent || false
}

export function hasPermissionForSerie (serie, roles) {
  if (serie == null) return false
  const { id } = serie
  if (serie.id == null) return false
  const hasAccesToSerie = isAuthorizedForAffiliatePath([id], roles)
  return hasAccesToSerie || false
}

export function isAffiliateActivated () {
  const status = get(store, 'getters.affiliate.status')
  return status === 'ACTIVATED'
}

/*
// Currently not in use:

export function getRolesForPath (pathToCheck) {
  const accessPaths = get(store, 'state.current.user.accessPaths')
  if (accessPaths == null) return false
  if (pathToCheck == null) return false
  return reduce(accessPaths, function (memo, { path, roles }) {
    if (isSubPath(path, pathToCheck)) return union(memo, roles)
    return memo
  }, [])
}

export function isAuthorizedForPaths (paths, ...rolesToCheck) {
  return find(paths, (path) => isAuthorizedForPath(path, ...rolesToCheck) === true) !== null
}

export function isAuthorizedForAffiliatePaths (paths, ...rolesToCheck) {
  const affiliateId = get(store, 'state.current.affiliate.id')
  if (!affiliateId) return false
  return isAuthorizedForPaths(paths.map(path => [affiliateId, ...path]), ...rolesToCheck)
}
*/
