import Vue from 'vue'
import axios from 'axios'
import { apiPath } from '@/config'

export default {
  namespaced: false,
  state: () => {
    return {
      items: {}
    }
  },
  mutations: {
    findPromotionResult: (state, { item }) => {
      Vue.set(state.items, item.id, item)
    }
  },
  getters: {
  },
  actions: {
    findPromotion: ({ commit, state: { items } }, { id, lazy }) => {
      if (lazy && items[id] != null) return Promise.resolve(items[id])
      return new Promise((resolve, reject) => {
        return axios.get(`${apiPath}/promotion/${id}`)
          .then(({ data }) => {
            commit('findPromotionResult', { item: data })
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    updatePromotion: ({ commit }, { item }) => {
      const { id } = item
      return new Promise((resolve, reject) => {
        axios.put(`${apiPath}/promotion/${id}`, item).then(({ data }) => {
          commit('findPromotionResult', { item: data })
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    updatePromotionStatus: ({ commit }, { item }) => {
      const { id } = item
      return new Promise((resolve, reject) => {
        axios.patch(`${apiPath}/promotion/${id}/status`, item).then(({ data }) => {
          commit('findPromotionResult', { item: data })
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    createPromotion: ({ commit, getters: { affiliateId } }, { item }) => {
      return new Promise((resolve, reject) => {
        axios.post(`${apiPath}/promotion`, { ...item, affiliateId }).then(({ data }) => {
          commit('findPromotionResult', { item: data })
          resolve(data)
        }).catch(e => reject(e))
      })
    }
  }
}
