<template>
  <div>
    <v-snackbar
      v-model="show"
      :timeout="snack.timeout"
      :color="snack.color"
      :top="snack.top"
      :bottom="snack.bottom"
      :left="snack.left"
      :right="snack.right"
    >
      {{ snack.text || $t(snack.key) }}
      <template #action>
        <v-btn dark text icon class="ml-3" @click="show = false">
          <v-icon>clear</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'Notification',
  computed: {
    ...mapState({
      snack: ({ notification }) => notification
    }),
    show: {
      get () {
        return this.snack.show
      },
      set (visible) {
        if (!visible) this.$store.dispatch('notify', { show: false })
      }
    }
  }
}
</script>
