import axios from 'axios'
import { apiPath } from './../../config'
import moment from 'moment'
import 'moment-timezone'
export default {
  namespaced: false,
  state: () => {
    return {
      feature: {}
    }
  },
  mutations: {
    setGlobal: (state, data) => {
      const { country: { timezone } } = data
      moment.tz.setDefault(timezone)
      Object.assign(state, data)
    }
  },
  actions: {
    loadGlobal: ({ commit }) => {
      return new Promise((resolve, reject) => {
        axios.get(`${apiPath}/global`).then(({ data }) => {
          commit('setGlobal', data)
          resolve(data)
        }).catch((e) => reject(e))
      })
    }
  }
}
