<template>
  <v-textarea
    v-bind="$attrs"
    :label="_label"
    hide-details="auto"
    :error-messages="_errorMessages"
    :value="value"
    :placeholder="_placeholder"
    outlined
    v-on="$listeners"
  >
    <!-- Pass on all named slots -->
    <slot v-for="slot in Object.keys($slots)" :slot="slot" :name="slot" />
    <!-- Pass on all scoped slots -->
    <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </v-textarea>
</template>

<script>
import fieldWrapper from '@/mixins/fieldWrapper'
export default {
  name: 'TextareaField',
  mixins: [fieldWrapper],
  inheritAttrs: false
}
</script>
