import Vue from 'vue'
import axios from 'axios'
import { apiPath } from '../../config'
import qs from 'qs'
import { cloneDeep } from 'lodash-es'
import { sesamMappingValue as createMappingValue } from '@/utils/defaults'

export function prepare (item) {
  item = cloneDeep(item)

  const parseDecimal = Vue.filter('parseDecimal')

  if (item.ticketAreas) {
    item.ticketAreas.forEach(({ ticketTypes }) => {
      ticketTypes.forEach(ticketType => (ticketType.price = parseDecimal(ticketType.price)))
    })
  }

  return item
}

export default {
  namespaced: false,
  state: () => {
    return {
      items: {}
    }
  },
  mutations: {
    findEventResult: (state, { item }) => {
      const formatDecimal = Vue.filter('decimal')
      item.ticketAreas.forEach(({ ticketTypes }) => {
        ticketTypes.forEach(ticketType => (ticketType.price = formatDecimal(ticketType.price)))
      })
      item.seatmap = item.sesamMapping != null
      if (item.sesamMapping) {
        item.sesamMapping.values = item.sesamMapping.values.map(m => Object.assign(createMappingValue(), m, { readonly: false }))
      }
      Vue.set(state.items, item.id, item)
    },

    deleteEventResult: (state, { item }) => {
      Vue.delete(state.items, item.id)
    }
  },
  getters: {
  },
  actions: {
    findEvent: ({ commit, dispatch, getters: { affiliateId } }, { id }) => {
      return new Promise((resolve, reject) => {
        const query = qs.stringify({ affiliateId })
        axios.get(`${apiPath}/event/${id}?${query}`)
          .then(({ data }) => {
            const { eventSeriesId } = data
            const promises = []
            if (eventSeriesId) promises.push(dispatch('findSerie', { id: eventSeriesId, lazy: true }))
            Promise.all(promises).then(() => {
              commit('findEventResult', { item: data })
              resolve(data)
            }).catch(e => reject(e))
          })
          .catch((error) => reject(error))
      })
    },

    updateEvent: ({ commit }, { item }) => {
      const { id } = item
      item = prepare(item)

      return new Promise((resolve, reject) => {
        axios.put(`${apiPath}/event/${id}`, item).then(({ data }) => {
          commit('findEventResult', { item: data })
          commit('updateEventsResultItem', { item: data })
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    patchEvent: ({ commit }, { item }) => {
      const { id } = item
      return new Promise((resolve, reject) => {
        axios.patch(`${apiPath}/event/${id}`, item).then(({ data }) => {
          commit('findEventResult', { item: data })
          commit('updateEventsResultItem', { item: data })
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    updateEventStatus: ({ dispatch, commit }, { id, status, checksum = undefined, skipChecksum }) => {
      return new Promise((resolve, reject) => {
        axios.patch(`${apiPath}/event/${id}/status`, { id, status, checksum, skipChecksum }).then(({ data }) => {
          dispatch('GTM_PUSH', {
            event: 'gaEvent',
            event_name: `event_${status.toLowerCase()}`,
            [`event_${status.toLowerCase()}`]: {
              event_category:'Event Status',
              event_action: 'Unpublished',
              event_label: id,
              event_id: id
            }
          })
          commit('findEventResult', { item: data })
          commit('updateEventsResultItem', { item: data })
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    createEvent: ({ commit }, { item }) => {
      item = prepare(item)

      return new Promise((resolve, reject) => {
        axios.post(`${apiPath}/event`, item).then(({ data }) => {
          commit('findEventResult', { item: data })
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    deleteEvent: ({ commit }, { id }) => {
      return new Promise((resolve, reject) => {
        axios.delete(`${apiPath}/event/${id}`)
          .then(function ({ data }) {
            commit('deleteEventResult', { item: data })
            commit('deleteEventsResultItem', { item: data })
            resolve(data)
          }).catch(e => reject(e))
      })
    }
  }
}
