<template>
  <v-fade-transition>
    <v-alert v-if="error != null" type="error" icon="error">
      <div>{{ message }}</div>
      <ul v-if="validations" class="my-2">
        <li v-for="(error, i) in validations" :key="i">
          {{ $tk(`fields.${error.path}`) }}: {{ error.message }}
        </li>
      </ul>
      <small v-if="!hideRef && reference != null" style="opacity: 0.6">{{ reference }}</small>
    </v-alert>
  </v-fade-transition>
</template>

<script>
import errorMixin from '@/mixins/error'
export default {
  name: 'ErrorAlert',
  mixins: [errorMixin]
}
</script>
