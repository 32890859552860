<template>
  <v-card>
    <v-card-title primary-title>
      {{ $t('behalf-of.title') }}
    </v-card-title>
    <div style="height:2px;">
      <v-progress-linear v-show="loading" indeterminate color="yellow darken-2" />
    </div>
    <v-card-text class="mt-2">
      <error-alert :error="error" class="mb-5" />
      <v-form class="pt-3" @submit.prevent.stop="send()">
        <text-field v-model.trim="affiliateId" :disabled="loading" field="affiliateId" name="affiliateId" />
      </v-form>
    </v-card-text>
    <v-card-actions class="px-6 pb-4">
      <v-spacer />
      <v-btn depressed text @click="$emit('close')">
        {{ $t('button.cancel') }}
      </v-btn>
      <v-btn depressed text color="primary" :disabled="loading" @click.prevent.stop="send()">
        {{ $t('button.ok') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { validationMixin } from 'vuelidate'
import errorsMixin from '@/mixins/errors'
import { required, maxLength, minLength } from 'vuelidate/lib/validators'
import ErrorAlert from '@/components/ErrorAlert'
import log from 'loglevel'

export default {
  name: 'ResetAffiliateId',
  components: {
    ErrorAlert
  },
  mixins: [validationMixin, errorsMixin],
  validations () {
    return {
      affiliateId: {
        required,
        minLength: minLength(24),
        maxLength: maxLength(24)
      }
    }
  },
  data: () => {
    return {
      affiliateId: null,
      error: null,
      loading: false
    }
  },
  watch: {
    affiliateId () {
      this.error = null
    }
  },
  methods: {
    async send () {
      if (!await this.$validateAll()) return
      const { affiliateId } = this
      this.loading = true
      try {
        await this.$store.dispatch('behalfOf', { affiliateId })
        this.$emit('close')
        this.$router.push('/')
      } catch (error) {
        log.debug(error)
        this.error = error
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
</style>
