import Vue from 'vue'
import axios from 'axios'
import { apiPath } from './../../config'
import { get } from 'lodash-es'

const languageToLocale = {
  en: 'en_GB',
  fi: 'fi_FI',
  de: 'de_DE',
  da: 'da_DK',
  sv: 'sv_SE',
  nl: 'nl_NL',
  fr: 'fr_FR',
  it: 'it_IT',
  es: 'es_ES',
  nb: 'nb_NO'
}

export default {
  namespaced: false,
  state: () => {
    return {
      shopConfig: null,
      affiliate: null,
      user: null,
      behalfOf: null
    }
  },
  mutations: {
    setCurrent: (state, { user, affiliate, shopConfig }) => {
      if (user) Vue.set(state, 'user', user)
      if (affiliate) Vue.set(state, 'affiliate', affiliate)
      if (shopConfig) Vue.set(state, 'shopConfig', shopConfig)
    },
    patchCurrentUser: (state, user) => {
      Object.assign(state.user, user)
    },
    behalfOf: (state, affiliate) => {
      Vue.set(state, 'behalfOf', affiliate)
    },
    patchCurrentCustomData: (state, customData) => {
      Vue.set(state.user,'customData', customData)
    },
    resetCurrent: (state) => {
      state.affiliate = null
      state.shopConfig = null
      state.user = null
      state.behalfOf = null
    }
  },
  getters: {
    affiliate: ({ affiliate, behalfOf }) => behalfOf || affiliate,
    behalfOf: ({ behalfOf }) => behalfOf,
    affiliateId: (_, { affiliate }) => affiliate != null ? affiliate.id : null,
    userId: ({ user }) => user != null ? user.id : null,
    user: ({ user }) => user,
    userLanguage: ({ user }) => user != null && user.locale != null ? user.locale.substr(0, 2) : null,
    requiresTermsAcceptance: ({ affiliate }, _, { global: { feature: { viewTerms } } }) => {
      return viewTerms && get(affiliate, 'termsAndConditionsAcceptedDate') == null
    },
    requiresSepa: ({ affiliate }, _, { global: { feature: { viewSepa, sales } } }) => {
      return sales && viewSepa && get(affiliate, 'feature.sales') === true && get(affiliate, 'sepaMandateAcceptedDate') == null
    }
  },
  actions: {
    loadCurrent: ({ commit, dispatch, rootState: { auth: { token } } }) => {
      if (token == null) throw new Error('No token provided')
      return axios.get(`${apiPath}/current`)
        .then(function ({ data: { user, affiliate, shopConfig } }) {
          commit('setCurrent', { user, affiliate, shopConfig })
        })
        .catch(function (error) {
          dispatch('reset')
          throw error
        })
    },
    behalfOf: async ({ commit, rootState: { auth: { token } } }, { affiliateId }) => {
      if (token == null) throw new Error('No token provided')
      commit('behalfOf', null)
      try {
        const { data } = await axios.get(`${apiPath}/affiliate/${affiliateId}`)
        if (data && data.id) commit('behalfOf', data)
      } catch (e) {
        commit('behalfOf', null)
        throw e
      }
    },
    acceptTerms: ({ dispatch, getters: { isAuthenticated, affiliateId } }, { accept }) => {
      if (!isAuthenticated) throw Promise.reject(new Error('Not authenticated'))
      return new Promise((resolve, reject) => {
        axios.put(`${apiPath}/affiliate/${affiliateId}/acceptTermsAndConditions`, { acceptTermsAndConditions: accept })
          .then(() => {
            dispatch('loadCurrent').then(resolve, reject)
          }).catch((error) => {
            reject(error)
          })
      })
    },
    acceptSepa: ({ dispatch, getters: { isAuthenticated, affiliateId } }, { accept }) => {
      if (!isAuthenticated) throw Promise.reject(new Error('Not authenticated'))
      return new Promise((resolve, reject) => {
        axios.put(`${apiPath}/affiliate/${affiliateId}/acceptSepaMandate`, { acceptSepaMandate: accept })
          .then(() => {
            dispatch('loadCurrent').then(resolve, reject)
          }).catch((error) => {
            reject(error)
          })
      })
    },
    patchCurrent: ({ commit, getters: { user } }, { language }) => {
      const locale = language ? languageToLocale[language] : undefined
      if (!locale || locale === user.locale) return
      commit('patchCurrentUser', { locale })
      return axios.patch(`${apiPath}/user/current`, { locale })
        .then(function ({ data: { user } }) {
          commit('setCurrent', { user })
        })
    },
    patchCurrentCustomData: ({ commit, getters: { isAuthenticated, userId } }, customData) => {
      if (!isAuthenticated) throw new Error('Not authenticated')
      commit('patchCurrentCustomData', customData)
      return axios.patch(`${apiPath}/user/${userId}/customData`, customData)
        .then(function ({ data }) {
          commit('patchCurrentCustomData', data)
        })
        .catch(function (error) {
          throw error
        })
    }
  }
}
