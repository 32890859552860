import axios from 'axios'
import { apiPath, prefix } from './../../config'
import log from 'loglevel'

const ls = window.localStorage

export default {
  namespaced: false,
  state: () => {
    const token = ls.getItem(`${prefix}_token`) || null
    axios.defaults.headers.common.Authorization = token != null ? `Bearer ${token}` : undefined
    return {
      active: true,
      visible: true,
      busy: false,
      token
    }
  },
  mutations: {
    setAuthToken: (state, { token }) => {
      state.busy = false
      state.token = token
      ls.setItem(`${prefix}_token`, token)
      axios.defaults.headers.common.Authorization = `Bearer ${token}`
    },
    resetAuth: (state) => {
      state.busy = false
      state.token = null
      axios.defaults.headers.common.Authorization = undefined
      ls.removeItem(`${prefix}_token`)
    },
    setAuthBusy: (state) => {
      state.busy = true
    },
    setActive: (state, status) => {
      state.active = status === true
    },
    setVisible: (state, status) => {
      state.visible = status === true
    }
  },
  getters: {
    isAuthenticated: ({ token }) => token != null
  },
  actions: {
    login: ({ getters: { isAuthenticated }, commit, dispatch }, { username, password }) => {
      if (isAuthenticated) return Promise.reject(new Error('Already authenticated'))
      return new Promise((resolve, reject) => {
        commit('setAuthBusy', true)
        return axios.post(`${apiPath}/authentication`, { username, password })
          .then(({ data: { token, expiresIn } }) => {
            commit('setAuthToken', { token, expiresIn })
            dispatch('loadCurrent').then(resolve)
          })
          .catch((error) => {
            dispatch('reset')
            reject(error)
          })
      })
    },
    loginWithToken: async ({ commit, dispatch }, { token }) => {
      commit('setAuthBusy', true)
      commit('setAuthToken', { token })
      try {
        await dispatch('loadCurrent')
      } catch (error) {
        dispatch('reset')
        throw error
      }
    },
    extend: ({ getters: { isAuthenticated }, state: { active, token }, commit, dispatch }, { force } = {}) => {
      if (!active && !force) {
        log.debug('Skipping token extension, idle = true')
        return Promise.resolve()
      }
      if (!isAuthenticated) return Promise.reject(new Error('Not authenticated'))
      return new Promise((resolve, reject) => {
        return axios.patch(`${apiPath}/authentication/extend`, { code: token })
          .then(({ data: { token, expiresIn } }) => {
            commit('setAuthToken', { token, expiresIn })
            resolve()
          })
          .catch((error) => {
            dispatch('reset')
            reject(error)
          })
      })
    },
    logout: ({ commit, dispatch, getters: { isAuthenticated } }) => {
      commit('setAuthBusy', true)
      if (isAuthenticated) {
        axios.post(`${apiPath}/authentication/delete`)
          .then(() => {
            dispatch('reset')
          })
          .catch(() => {
            dispatch('reset')
          })
      } else {
        dispatch('reset')
      }
    },
    restoreAuth: ({ dispatch, state: { token }, getters: { isAuthenticated } }) => {
      return new Promise((resolve) => {
        if (token == null && !isAuthenticated) return resolve()
        dispatch('loadCurrent')
          .then(() => {
            resolve()
            dispatch('extend', { force: true })
          })
          .catch(() => {
            dispatch('logout')
            resolve()
          })
      })
    }
  }
}
