<template>
  <v-container class="fill-height justify-center" fluid>
    <v-progress-circular :size="100" color="primary" class="align-self-center" indeterminate />
  </v-container>
</template>

<script>
export default {
  name: 'Loading'
}
</script>
