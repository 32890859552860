import axios from 'axios'
import { apiPath } from './../../config'

export default {
  namespaced: false,
  actions: {
    createLocation: (_, { item }) => {
      return new Promise((resolve, reject) => {
        axios.post(`${apiPath}/venue`, item).then(({ data }) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    }
  }
}
