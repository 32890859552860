import { last } from 'lodash-es'

export default {
  props: {
    value: { required: false },
    field: { type: String, required: true },
    required: { type: Boolean, required: false },
    label: { type: String, required: false },
    labelKey: { type: String, required: false },
    additionalFields: { type: Array, required: false, default: () => [] },
    customErrors: { type: Array, required: false, default: () => [] },
    hint: { type: Boolean, required: false },
    placeholder: { type: String, required: false },
    shopPreviewError: { type: Boolean, required: false }
  },
  computed: {
    vparent () {
      let parent = this
      do {
        parent = parent.$parent
      } while (parent != null && parent.$parent != null && parent.$hasFieldError == null)
      return parent.$hasFieldError != null ? parent : null
    },
    _labelKey () {
      const key = last(this.field.split('.'))
      return `fields.${key}`
    },
    _errorMessages () {
      const { vparent, field, labelKey, _labelKey, additionalFields, label, shopPreviewError } = this
      if (vparent == null) return []
      const errors = vparent.$fieldsError(field, ...additionalFields, { labelKey: labelKey || _labelKey, label, shopPreviewError })
      return [...this.customErrors, ...errors]
    },
    _label () {
      const { vparent, labelKey, _labelKey, required, field, label } = this
      const isRequired = vparent == null ? false : (required || field)
      if (label) return (vparent || this).$f(label, isRequired)
      return (vparent || this).$tf(labelKey || _labelKey, isRequired)
    },
    _hint () {
      const { hint, labelKey } = this
      if (!hint) return
      return this.$tf(`hint.${labelKey}`)
    },
    _placeholder () {
      const { placeholder } = this
      if (!placeholder) return
      return this.$tf(placeholder)
    }
  }
}
