<template>
  <v-container class="fill-height justify-center text-center">
    <div>
      <v-img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ny41IDQ3LjUiPjxkZWZzPjxjbGlwUGF0aCBpZD0iYSI+PHBhdGggZD0iTTAgMzhoMzhWMEgwdjM4eiIvPjwvY2xpcFBhdGg+PC9kZWZzPjxnIGNsaXAtcGF0aD0idXJsKCNhKSIgdHJhbnNmb3JtPSJtYXRyaXgoMS4yNSAwIDAgLTEuMjUgMCA0Ny41KSI+PHBhdGggZD0iTTM2IDE5YzAtOS4zODktNy42MTEtMTctMTctMTdTMiA5LjYxMSAyIDE5czcuNjExIDE3IDE3IDE3IDE3LTcuNjExIDE3LTE3IiBmaWxsPSIjZmZjYzRkIi8+PHBhdGggZD0iTTE1IDIzYzAtMS42NTctLjk2LTMtMi4xNDMtMy0xLjE4NCAwLTIuMTQzIDEuMzQzLTIuMTQzIDNzLjk1OSAzIDIuMTQzIDNDMTQuMDQgMjYgMTUgMjQuNjU3IDE1IDIzTTI3IDIzLjJjMC0xLjU0Ny0uODk1LTIuOC0yLTIuOC0xLjEwNCAwLTIgMS4yNTMtMiAyLjggMCAxLjU0Ni44OTYgMi44IDIgMi44IDEuMTA1IDAgMi0xLjI1NCAyLTIuOE0yMyAxMmMwLTIuNzYzLTEuNzkyLTUtNC01LTIuMjEgMC00IDIuMjM3LTQgNSAwIDIuNzYxIDEuNzkgNSA0IDUgMi4yMDggMCA0LTIuMjM5IDQtNSIgZmlsbD0iIzY2NDUwMCIvPjwvZz48L3N2Zz4=" class="page-error__icon ma-auto mb-3" :max-width="200" />
      <h1 class="mb-1">
        {{ title }}
      </h1>
      <p class="mb-1">
        {{ message }}
      </p>
      <ul v-if="validations" class="my-2">
        <li v-for="(error, i) in validations" :key="i">
          {{ $tk(`fields.${error.path}`) }}: {{ error.message }}
        </li>
      </ul>
      <div v-if="!hideRef && reference != null" class="mb-5">
        <small style="opacity: 0.6">{{ reference }}</small>
      </div>
      <v-btn depressed to="/" color="primary" large>
        {{ $t('button.back') }}
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import Base from '@/mixins/base'
import ErrorMixin from '@/mixins/error'
export default {
  name: 'Error',
  mixins: [Base, ErrorMixin],
  metaInfo () {
    return {
      title: this.title
    }
  }
}
</script>

<style>
.error__icon {
  max-height: 200px
}
</style>
