import axios from 'axios'
import { apiPath } from './../../config'

export default {
  namespaced: false,
  state: () => {
  },
  actions: {
    contact: (_, { data: payload }) => {
      return new Promise((resolve, reject) => {
        axios.post(`${apiPath}/global/contact`, payload)
          .then(({ data }) => { resolve(data) })
          .catch((error) => reject(error))
      })
    }
  }
}
