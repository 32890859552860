import moment from 'moment'

const createDefault = (name) => {
  return {
    name,
    dateFilter: {
      selected: 'custom',
      dateSince: moment().startOf('day').format(),
      dateUntil: null
    },
    timeFilter: {
      timeSince: null,
      timeUntil: null
    },
    status: 'ALL',
    selectedDays: [],
    eventtypeFilter: {
      eventSeriesId: null,
      title: null
    }
  }
}

const defaults = () => [createDefault('events'), createDefault('series-view'), createDefault('promotion')]
export default {
  namespaced: false,
  state: () => {
    return {
      filter: defaults()
    }
  },
  getters: {
    eventsTableFilter: ({ filter }) => (name) => filter.find(f => f.name === name)
  },
  mutations: {
    patchFilter ({ filter }, { name, ...rest }) {
      const index = filter.findIndex(f => f.name === name)
      Object.assign(filter[index], rest)
    },
    setDefault ({ filter }, { name, persist }) {
      const index = filter.findIndex(f => f.name === name)
      filter.splice(index, 1)
      filter.push({ ...createDefault(name), ...persist })
    }
  },
  actions: {
    updateFilter ({ commit }, props) {
      commit('patchFilter', props)
    },
    resetFilter ({ commit }, { name, persist }) {
      commit('setDefault', { name, persist })
    }
  }
}
