import Vue from 'vue'
import axios from 'axios'
import { apiPath } from '../../config'

export default {
  namespaced: false,
  state: () => {
    return {}
  },
  mutations: {
    setReservedSeats: (state, { id, items }) => {
      Vue.set(state, id, items)
    }
  },
  getters: {},
  actions: {
    findReservedSeats: async ({ commit }, { id }) => {
      if (!id) return
      const { data } = await axios.get(`${apiPath}/event/${id}/seat/reserved`)
      commit('setReservedSeats', { id: id, items: data })
    }
  }
}
