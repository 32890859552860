import xrc from 'x-result-count'
import axios from 'axios'
import qs from 'qs'
import { apiPath } from './../../config'
import { isEmpty } from 'lodash-es'
import Vue from 'vue'

export default {
  namespaced: false,
  state: () => {
    return {
      items: {},
      page: {
        skip: null,
        count: null,
        total: null
      }
    }
  },
  mutations: {
    findReportResult: (state, { page, items, id }) => {
      Object.assign(state.page, page)
      Vue.set(state.items, id, items)
    }
  },
  actions: {
    findReport: ({ commit }, { id, since, until, startDateSince, startDateUntil, sort, eventSeriesId, skip = 0, limit = 25, status, channelMap }) => {
      status = !isEmpty(status) ? status.join(',') : null
      const query = qs.stringify({
        limit,
        sort,
        skip,
        since,
        until,
        startDateSince,
        startDateUntil,
        eventSeriesId,
        status,
        tdl: channelMap.tdl,
        affiliate: channelMap.affiliate,
        shop: channelMap.shop
      }, { skipNulls: true })
      return axios.get(`${apiPath}/affiliate/${id}/report?${query}`)
        .then(({ data, headers }) => {
          const page = xrc.parse(headers['x-result-count'])
          commit('findReportResult', { page, items: data, id })
        })
        .catch((error) => {
          throw error
        })
    }
  }
}
