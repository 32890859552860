// import Vue from 'vue'
import axios from 'axios'
import { apiPath } from '@/config'
import xrc from 'x-result-count'
import qs from 'qs'

export default {
  namespaced: false,
  state: () => {
    return {
      items: {},
      list: [],
      page: {
        skip: null,
        count: null,
        total: null
      }
    }
  },
  mutations: {
    findSeriesResult: (state, { page, data }) => {
      Object.assign(state.page, page)
      state.list = data.map(entry => entry.id)
      data.forEach(entry => (state.items[entry.id] = entry))
    }
  },
  getters: {
    eventSeries: ({ list, items }, _, { global: { categories } }) => {
      return list.map(id => {
        const item = items[id]
        const category = categories.find((c) => c.id === item.eventCategory) || {}
        return { ...item, eventCategoryName: category.name }
      })
    }
  },
  actions: {
    findSeries: ({ commit, getters: { affiliateId } }, { skip = 0, limit = 9999, sort, status }) => {
      const query = qs.stringify({ skip, limit, sort, status, affiliateId })
      return axios.get(`${apiPath}/eventSeries?${query}`)
        .then(function ({ data, headers }) {
          const page = xrc.parse(headers['x-result-count'])
          commit('findSeriesResult', { page, data })
        })
        .catch(function (error) {
          throw error
        })
    }
  }
}
