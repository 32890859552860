import axios from 'axios'
import { apiPath } from './../../config'

export default {
  namespaced: false,
  state: () => {
  },
  mutations: {
  },
  getters: {
  },
  actions: {
    forgetPassword: (_, { data }) => {
      return axios.post(`${apiPath}/registration/forgotPassword`, data)
    },
    changePassword: ({ getters: { userId } }, { data }) => {
      return axios.patch(`${apiPath}/user/${userId}/password`, data)
    }
  }
}
