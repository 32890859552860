import { trimToNull } from './common'
import { isAuthorizedForAffiliatePath, hasPermissionForEvent, hasPermissionForSerie } from '@/utils/auth'
import { hasFeature, hasAffiliateFeature } from '@/utils/feature'
import { get, isString, kebabCase } from 'lodash-es'
import { required } from 'vuelidate/lib/validators'
import log from 'loglevel'

export default {
  install (Vue) {
    Vue.prototype.$log = log

    Vue.prototype.$trimToNull = (s) => trimToNull(s)
    Vue.prototype.$isNullOrEmpty = (s) => trimToNull(s) == null
    Vue.prototype.$isNullOrEmpty = (s) => trimToNull(s) == null

    Vue.prototype.$feature = function (key) {
      const active = hasFeature(key) || false
      // log.debug(`$feature(${key}) =`, active)
      return active
    }

    Vue.prototype.$affiliateFeature = function (key) {
      const active = (hasFeature(key) && hasAffiliateFeature(key)) || false
      // log.debug(`$affiliateFeature(${key}) =`, active)
      return active
    }

    Vue.prototype.$hasPermission = function (path, roles) {
      return isAuthorizedForAffiliatePath(path, roles) || false
    }

    Vue.prototype.$hasPermissionForEvent = function (event, roles) {
      return hasPermissionForEvent(event, roles) || false
    }

    Vue.prototype.$hasPermissionForSerie = function (serie, roles) {
      return hasPermissionForSerie(serie, roles) || false
    }

    Vue.prototype.$f = function (label, path) {
      const pathWithoutIndex = isString(path) && path.indexOf('$each') !== -1 ? path.replace(/\.\$each\.[0-9]+\./g, '.$each.') : path
      const isRequiredFn = isString(path) ? get(this.$validationRules, `${pathWithoutIndex}.required`) : null
      return label + ((isRequiredFn === required || path === true) ? ' *' : '')
    }

    Vue.prototype.$tf = function (key, path) {
      const translationKey = key.split('.').map(kebabCase).join('.')
      return Vue.prototype.$f(this.$t(translationKey), path)
    }

    Vue.prototype.$tk = function (key, a, b, c) {
      return this.$t(key.split('.').map(kebabCase).join('.'), a, b, c)
    }
  }
}
