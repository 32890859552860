import Vue from 'vue'

import Nl2br from './components/nl2br'
import TextField from './components/fields/TextField'
import FileInputField from './components/fields/FileInputField'
import SelectField from './components/fields/SelectField'
import TextareaField from './components/fields/TextareaField'

Vue.component('TextField', TextField)
Vue.component('FileInputField', FileInputField)
Vue.component('SelectField', SelectField)
Vue.component('TextareaField', TextareaField)

Vue.component('Nl2br', Nl2br)
