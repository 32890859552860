import Vue from 'vue'
import axios from 'axios'
import { configPath, prefix } from './../../config'
import log from 'loglevel'
const ls = window.localStorage

export default {
  namespaced: false,
  state: () => {
    const language = ls.getItem(`${prefix}_language`) || null
    return {
      busy: false,
      language,
      loaded: []
    }
  },
  mutations: {
    setLanguageLoading: (state, yes) => {
      state.busy = yes === true
    },
    setLanguageBundle: (state, { language, bundle }) => {
      Vue.set(state, language, bundle)
    },
    setLanguage: (state, { language }) => {
      state.language = language
    }
  },
  getters: {
    language ({ language }, { userLanguage }, { config: { languages } }) {
      const tries = [userLanguage, language, languages[0], 'en'].filter(lang => {
        if (lang == null) return false
        if (languages.indexOf(lang) === -1) return false
        return true
      })

      return tries[0]
    }
  },
  actions: {
    initLanguage: ({ dispatch, getters: { language } }) => {
      return dispatch('findLanguageBundle', { language })
    },
    setLanguage: ({ commit, dispatch, rootState: { ready, config: { languages } }, getters: { isAuthenticated } }, { language }) => {
      return new Promise((resolve, reject) => {
        if (!language) return reject(new Error('no language provided'))
        if (languages.indexOf(language) === -1) {
          if (!ready) return resolve()
          return reject(new Error(`language not available: ${language} of ${languages.join(',')}`))
        }
        dispatch('findLanguageBundle', { language }).then(() => {
          commit('setLanguage', { language })
          if (isAuthenticated) dispatch('patchCurrent', { language })
          ls.setItem(`${prefix}_language`, language)
          resolve()
        })
      })
    },
    findLanguageBundle: ({ commit, state }, { language }) => {
      if (state[language] != null) return Promise.resolve()
      commit('setLanguageLoading', true)

      if (language !== 'en') import('moment/locale/' + language + '.js').catch(log.info)

      return new Promise((resolve, reject) => {
        axios.get(`${configPath}/i18n/${language}`).then(({ data: bundle }) => {
          commit('setLanguageBundle', { language, bundle })
          commit('setLanguageLoading', false)
          resolve()
        }, reject)
      })
    }
  }
}
