export default {
  namespaced: false,
  state: () => ({
    exportConsent: undefined
  }),
  actions: {
    CM_EXPORT_SAVE: ({ commit }, exportConsent ) => {
      if (exportConsent == null) return
      commit('CM_EXPORT_SAVED', exportConsent)
    },
  },
  mutations: {
    CM_EXPORT_SAVED (state, exportConsent) {
      state.exportConsent = exportConsent
    }
  }
}
