import store from '@/store/store'
import { get } from 'lodash-es'

export function hasMarketingHelp () {
  return store.state.config.marketing && store.state.config.marketing.help
}

export function hasFeature (key) {
  return get(store, `state.global.feature.${key}`) === true || get(store, `state.config.feature.${key}`) === true
}

export function hasAffiliateFeature (key) {
  if (store.getters.behalfOf != null) return get(store, `state.current.behalfOf.feature.${key}`) === true
  return get(store, `state.current.affiliate.feature.${key}`) === true
}
