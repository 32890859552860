import { trimToNull, trim as trimFn } from './utils/common'
import store from '@/store/store'
import { isString } from 'lodash-es'

export function host (url) {
  const host = url.replace(/^https?:\/\//, '').replace(/\/.*$/, '')
  const parts = host.split('.').slice(-3)
  if (parts[0] === 'www') parts.shift()
  return parts.join('.')
}

export function datetime (date) {
  if (date == null) return '?'
  return this.$df(date, 'datetime')
}

export function trim (str) {
  return trimFn(str)
}

export function initial (value) {
  if (isString(value)) value = value.split(' ')
  return value
    .filter(v => v != null)
    .map(v => v.trim())
    .filter(v => v != null && v !== '')
    .map(v => v.charAt(0).toUpperCase())
    .join('')
}

export function date (date) {
  if (date == null) return '?'
  return this.$df(date, 'date')
}

export function time (date) {
  if (date == null) return '?'
  return this.$df(date, 'time')
}

export function nl2br (v) {
  return v.replace(/\n/g, '<br>')
}

export function month (date) {
  if (date == null) return '?'
  return this.$df(date, 'month')
}

const toFixed = function (value, precision) {
  const power = Math.pow(10, precision)
  return (Math.round(value * power) / power).toFixed(precision)
}

const formatNumber = function (number, precision, thousand, decimal) {
  const base = parseInt(toFixed(Math.abs(number || 0), precision), 10) + ''
  const mod = base.length > 3 ? base.length % 3 : 0
  return (mod ? base.substr(0, mod) + thousand : '') + base.substr(mod).replace(/(\d{3})(?=\d)/g, '$1' + thousand) + (precision ? decimal + toFixed(Math.abs(number), precision).split('.')[1] : '')
}

const formatMoney = (amount, checkCurrency, key, currencyFormat, times) => {
  const amountObject = typeof amount === 'object'
  if (amount == null || (amountObject && amount[key] == null)) {
    return ''
  } else if (amountObject) {
    checkCurrency = amount.currency
    amount = amount[key]
  }

  if (isNaN(amount)) return ''

  let symbol = currencyFormat.symbol

  if (checkCurrency != null && checkCurrency !== currencyFormat.code) {
    symbol = checkCurrency
  }

  if (times != null && times > 1) {
    amount = amount * times
  }

  const format = amount > 0 ? currencyFormat.pos : amount < 0 ? currencyFormat.neg : currencyFormat.zero
  const formatted = formatNumber(amount, currencyFormat.precision, currencyFormat.thousand, currencyFormat.decimal)
  return format.replace('%s', symbol).replace('%v', formatted)
}

const unformatNumber = (value, decimal) => {
  value = value || 0
  if (typeof value === 'number') return value
  const regex = new RegExp('[^0-9-' + decimal + ']', ['g'])
  const unformatted = parseFloat(('' + value)
    .replace(/\((?=\d+)(.*)\)/, '-$1')
    .replace(regex, '')
    .replace(decimal, '.'))
  return !isNaN(unformatted) ? unformatted : 0
}

export function currency (amount, count) {
  return formatMoney(amount, null, 'value', store.state.config.currency, count)
}

export function parseDecimal (value) {
  const { decimal } = store.state.config.currency
  return unformatNumber(value, decimal)
}

export function decimal (amount, precision) {
  const currencyFormat = store.state.config.currency
  return formatNumber(amount, precision || currencyFormat.precision, currencyFormat.thousand, currencyFormat.decimal)
}

export function percent (amount) {
  const currencyFormat = store.state.config.currency
  const result = formatNumber(amount, 1, currencyFormat.thousand, currencyFormat.decimal)
  return (result != null && result.endsWith('0') ? result.substr(0, result.length - 2) : result) + ' %'
}

export function ticketFee (amount, count) {
  return formatMoney(amount, null, 'ticketFee', store.state.config.currency, count)
}

export function basePrice (amount, count) {
  return formatMoney(amount, null, 'basePrice', store.state.config.currency, count)
}

export function prepareFees (fee) {
  if (!fee) return fee
  const currencyFormat = store.state.config.currency
  fee = Object.assign({}, fee)
  if (fee.percentage) fee.percentage = formatNumber(fee.percentage * 100, 1, currencyFormat.thousand, currencyFormat.decimal)
  return fee
}

export function location (venue) {
  if (venue == null) return ''
  const { name, address } = venue
  const { street, zipCode, city } = address
  const zipCity = store.state.config.code === 'UK' ? `${city} ${zipCode}` : `${zipCode} ${city}`
  return [name, street, zipCity].map(trimToNull).filter(a => a != null).join(', ')
}

export function locationMedium (venue) {
  if (venue == null) return ''
  const { name, address = {} } = venue
  const { street } = address
  return [name, street].map(trimToNull).filter(a => a != null).join(', ')
}

export function locationShort (venue) {
  if (venue == null) return ''
  const { name, address } = venue
  const { city } = address
  return [name, city].map(trimToNull).filter(a => a != null).join(', ')
}

export function fullname (customer) {
  if (customer == null) return ''
  const { firstName, lastName } = customer
  return [lastName, firstName].map(trimToNull).filter(a => a != null).join(', ')
}
