const defaults = () => ({
  show: false,
  key: null,
  text: null,
  color: 'success',
  timeout: 3000,
  top: true,
  bottom: false,
  right: false,
  left: false
})

export default {
  namespaced: false,
  state: () => {
    return {
      ...defaults()
    }
  },
  mutations: {
    notification: (state, snack) => {
      if (snack.show === false) {
        state.show = false
      } else {
        state.show = false
        Object.assign(state, { ...defaults(), show: true, ...snack })
      }
    }
  },
  actions: {
    notify ({ commit }, snack) {
      commit('notification', snack)
    }
  }
}
