// import Vue from 'vue'
import axios from 'axios'
import { apiPath } from '@/config'

export default {
  namespaced: false,
  state: () => {
    return {
      items: {}
    }
  },
  actions: {
    copyEvent: async (_, { eventId, dates }) => {
      const { data } = await axios.post(`${apiPath}/event/${eventId}/copy`, { eventId, dates })
      return data
    }
  }
}
