<template>
  <v-card outlined :style="cardStyles">
    <div class="flex-grow-1">
      <v-card-title :class="titleClasses">
        <slot name="title">
          <v-icon v-if="icon" class="mr-2" :color="color">
            {{ icon }}
          </v-icon> {{ $t(`${prefix}.${type}.title`) }}
        </slot>
      </v-card-title>
      <v-card-text v-if="$te(`${prefix}.${type}.text`)" :class="textClasses">
        <slot name="text">
          <span v-if="!nl2br">{{ $t(`${prefix}.${type}.text`) }}</span>
          <nl2br v-else tag="div" :text="$t(`${prefix}.${type}.text`)" />
        </slot>
      </v-card-text>
      <v-card-actions v-if="$slots.default" :class="actionsClasses">
        <slot />
      </v-card-actions>
    </div>
  </v-card>
</template>
<script>

export default {
  name: 'Box',
  props: {
    type: { type: String, required: true },
    icon: { type: String, required: false },
    color: { type: String, required: false },
    outlined: { type: Boolean, require: false, default: false },
    nl2br: { type: Boolean, require: false, default: false}
  },
  computed: {
    prefix: () => 'box',
    cardClasses: () => ({}),
    cardStyles () {
      const { color } = this
      return {
        'border-color': color ? `var(--v-${color}-base)` : null,
        'border-width': color ? '2px' : '1px'
      }
    },
    titleClasses: () => ({ headline: true }),
    textClasses: () => ({}),
    actionsClasses: () => ({ 'px-4': true, 'pb-4': true })
  }
}
</script>
<style lang="scss">
</style>
