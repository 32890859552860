let loaded = false

export const load = (id) => {
  // Do nothing if run from server-side
  if (typeof document === 'undefined') return
  if (id == null) throw new Error('api key missing')
  if (!loaded) {
    loaded = true
    const setup = function (w, d, s, l, i) {
      w[l] = w[l] || []
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
      const f = d.getElementsByTagName(s)[0]
      const j = d.createElement(s)
      const dl = l !== 'dataLayer' ? '&l=' + l : ''
      j.async = true
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
      f.parentNode.insertBefore(j, f)
    }

    setup(window, document, 'script', 'dataLayer', id)
  }

  return loaded
}
