<script>
import Box from '@/components/Box'

export default {
  name: 'ConfirmBox',
  extends: Box,
  computed: {
    prefix: () => 'confirm'
  }
}
</script>

<style lang="scss">
</style>
