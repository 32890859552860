<template>
  <v-avatar v-if="avatar" :size="size" :tile="tile">
    <img :src="flagUrl">
  </v-avatar>
  <v-img v-else :src="flagUrl" :style="{ height: `${size}px`, width: `${size * (square ? 1 : 4/3)}px` }" />
</template>

<script>
import Base from '@/mixins/base'

const langMap = {
  de: 'de',
  en: 'gb',
  fi: 'fi',
  da: 'dk',
  sv: 'se',
  nl: 'nl',
  it: 'it',
  fr: 'fr',
  es: 'es',
  nb: 'no'
}

const isoMap = {
  ch: 'ch',
  at: 'at',
  de: 'de',
  gb: 'gb',
  fi: 'fi',
  dk: 'dk',
  se: 'se',
  es: 'es',
  no: 'no',
  nl: 'nl'
}

export default {
  name: 'VFlag',
  mixins: [Base],
  props: {
    lang: String,
    size: Number,
    square: Boolean,
    tile: Boolean,
    avatar: Boolean,
    platform: Boolean
  },
  computed: {
    flagUrl () {
      const locale = this.lang
      const countryCode = this.$store.state.config.code
      let lang = null
      let country = countryCode
      const map = {...langMap, ...(!this.platform ? {CH_de: 'ch'} : {}), ...(!this.platform ? {AT_de: 'at'} : {})}

      if (locale.includes('_')) {
        const s = locale.split('_')
        lang = s[1]
        country = s[0]
      }

      const code = map[`${countryCode}_${locale}`] || map[locale] || isoMap[locale] || (lang ? map[lang] : null) || country || null
      const flagType = this.square ? '1x1' : '4x3'
      const { assetBasePath } = this
      return code ? `${assetBasePath}flags/${flagType}/${code}.svg` : ''
    }
  }
}
</script>

<style lang="stylus">
</style>
