// import Vue from 'vue'
import axios from 'axios'
import { apiPath } from './../../config'
import xrc from 'x-result-count'
import qs from 'qs'

export default {
  namespaced: false,
  state: () => {
    return {
      items: {},
      list: [],
      page: {
        skip: null,
        count: null,
        total: null
      }
    }
  },
  mutations: {
    findAssetsResult: (state, { page, data }) => {
      Object.assign(state.page, page)
      state.list = data.map(entry => entry.id)
      data.forEach(entry => (state.items[entry.id] = entry))
    }
  },
  getters: {
    assetsList: ({ list, items }) => list.map(id => items[id])
  },
  actions: {
    findAssets: ({ commit, getters: { affiliateId } }, { skip = 0, limit = 20, sort, hasCopyright }) => {
      const query = qs.stringify({ skip, limit, sort, hasCopyright, active: true })
      return axios.get(`${apiPath}/asset/affiliateId/${affiliateId}?${query}`)
        .then(function ({ data, headers }) {
          const page = xrc.parse(headers['x-result-count'])
          commit('findAssetsResult', { page, data })
        })
        .catch(function (error) {
          throw error
        })
    }
  }
}
