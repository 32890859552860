import moment from 'moment'

export const prepareDate = (date) => {
  return moment(date)
}

export default {
  install (Vue) {
    Vue.prototype.$createDate = function (date) {
      return prepareDate(date)
    }
    Vue.prototype.$df = function (date, format) {
      date = prepareDate(date)
      const {
        state: { config: { formats } }
        // getters: { language }
      } = this.$store
      const finalFormat = (formats && formats[format]) || format
      return date.format(finalFormat)
    }

    Vue.prototype.$pd = function (str, format, strict) {
      const {
        state: { config: { formats } }
      } = this.$store
      const finalFormat = (formats && formats[format]) || format
      return moment(str, finalFormat, strict)
    }
  }
}
