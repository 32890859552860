import axios from 'axios'
import { apiPath } from '@/config'
import Vue from "vue";

export default {
  namespaced: false,
  state: () => {
    return {
      list: [],
      items: {},
      showAgreementDialog: false,
      currentAgreement: null
    }
  },
  mutations: {
    additionalAgreements: (state, { data }) => {
      state.list.splice(0, state.list.length, ...data.map(entry => entry.uuid))
      data.forEach(entry => Vue.set(state.items, entry.uuid, entry))
    },
    updateAdditionalAgreementsResultItem: (state, { item }) => {
      if (state.items[item.uuid]) Vue.set(state.items, item.uuid, item)
    },
    setShowAgreementDialog: (state, data) => {
      Object.assign(state, data)
    }
  },
  getters: {
    additionalAgreements: ({ list, items }) => list.map(id => items[id]).filter(e => e != null),
    presentedAgreements: ({ list, items }) => list.map(id => items[id]).filter(e => e.status === 'PRESENTED'),
    showAgreementDialog: ({ showAgreementDialog }) => showAgreementDialog,
    currentAgreement: ({ currentAgreement }) => currentAgreement
  },
  actions: {
    findAdditionalAgreements: ({ commit, getters: { affiliateId } }) => {
      if (!affiliateId) return
      return axios.get(`${apiPath}/agreement/affiliate/${affiliateId}`)
        .then(({ data }) => commit('additionalAgreements', { data }))
        .catch((error) => {
          throw error
        })
    },
    acceptAdditionalAgreements: ({ commit },{ item }) => {
      return axios.patch(`${apiPath}/agreement/${item.uuid}/accept`,{})
        .then(({ data: item }) => commit('updateAdditionalAgreementsResultItem', { item }))
        .catch((error) => {
          throw error
        })
    },
    declineAdditionalAgreements: ({ commit },{ item }) => {
      return axios.patch(`${apiPath}/agreement/${item.uuid}/decline`,{})
        .then(({ data: item }) => commit('updateAdditionalAgreementsResultItem', { item }))
        .catch((error) => {
          throw error
        })
    }
  }
}
