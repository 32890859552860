import axios from 'axios'
import { apiPath } from './../../config'
import qs from 'qs'
import moment from 'moment'
import Vue from 'vue'

function reducer (memo, { key, value }) {
  memo.TOTAL += value
  if (!memo[key]) memo[key] = value
  else memo[key] += memo[key]
  return memo
}

export default {
  namespaced: false,
  state: () => {
    return {
      until: [],
      since: []
    }
  },
  mutations: {
    setEventsStatusReport: (state, { until, since }) => {
      Vue.set(state, 'until', until)
      Vue.set(state, 'since', since)
    }
  },
  getters: {
    eventsReportByStatusUpcomming: ({ since }) => since.reduce(reducer, { UNPUBLISHED: 0, PUBLISHED: 0, TOTAL: 0 }),
    eventsReportByStatusPast: ({ until }) => until.reduce(reducer, { UNPUBLISHED: 0, PUBLISHED: 0, TOTAL: 0 })
  },
  actions: {
    loadEventsStatusReport: ({ commit, getters: { affiliateId } }) => {
      const query = qs.stringify({ date: moment().startOf('day').format() })
      return axios.get(`${apiPath}/affiliate/${affiliateId}/report/status?${query}`)
        .then(function ({ data: { until, since } }) {
          commit('setEventsStatusReport', { until, since })
        })
        .catch(function (error) {
          throw error
        })
    }
  }
}
