import Vue from 'vue'
import axios from 'axios'
import { apiPath } from './../../config'

export default {
  namespaced: false,
  state: () => {
    return {
      times: {}
    }
  },
  mutations: {
    findSeriesTimesResult: (state, { item, id }) => {
      Vue.set(state.times, id, item)
    }
  },
  getters: {},
  actions: {
    findSeriesTimes: ({ commit }, { id }) => {
      return axios.get(`${apiPath}/eventSeries/${id}/report/time`)
        .then(({ data }) => {
          commit('findSeriesTimesResult', { item: data, id: id })
        })
        .catch((error) => {
          throw error
        })
    }
  }
}
