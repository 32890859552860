<template>
  <v-select
    v-bind="$attrs"
    :label="_label"
    hide-details="auto"
    :error-messages="_errorMessages"
    :items="_items"
    :value="value"
    outlined
    v-on="$listeners"
  >
    <!-- Pass on all named slots -->
    <slot v-for="slot in Object.keys($slots)" :slot="slot" :name="slot" />
    <!-- Pass on all scoped slots -->
    <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </v-select>
</template>

<script>
import fieldWrapper from '@/mixins/fieldWrapper'
export default {
  name: 'SelectField',
  mixins: [fieldWrapper],
  inheritAttrs: false,
  props: {
    items: { required: false }
  },
  computed: {
    _items () {
      return this.items || []
    }
  }
}
</script>
