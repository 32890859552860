<template>
  <v-card v-if="error != null">
    <v-card-title>{{ title }}</v-card-title>
    <v-card-text class="pb-1">
      <div>{{ message }}</div>
      <ul v-if="validations" class="my-2">
        <li v-for="(error, i) in validations" :key="i">
          {{ $tk(`fields.${error.path}`) }}: {{ error.message }}
        </li>
      </ul>
      <small v-if="!hideRef && reference != null" style="opacity: 0.6">{{ reference }}</small>
    </v-card-text>
    <v-card-actions class="px-6 pb-6">
      <slot>
        <v-spacer />
        <v-btn depressed @click="$emit('close')">
          {{ $t('button.ok') }}
        </v-btn>
      </slot>
    </v-card-actions>
  </v-card>
</template>

<script>
import errorMixin from '@/mixins/error'
export default {
  name: 'ErrorBox',
  mixins: [errorMixin]
}
</script>
