
import store from '@/store/store'
import { location } from '@/filters'
import { upperFirst, trim } from 'lodash-es'
import moment from 'moment'
import log from 'loglevel'

export function init () {
  if (window.FB != null && window.loadedFbSdk) return Promise.resolve()

  return new Promise((resolve) => {
    const { getters: { language }, state: { global: { facebookAppId: appId, country: { iso } } } } = store

    window.fbAsyncInit = function () {
      window.FB.init({
        appId,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v17.0'
      })
      window.loadedFbSdk = true
      resolve()
    }

    const fjs = document.getElementsByTagName('title')[0]
    if (document.getElementById('facebook-jssdk')) return resolve()
    const js = document.createElement('script')
    js.id = 'facebook-jssdk'
    js.src = `https://connect.facebook.net/${language}_${iso}/sdk.js`
    fjs.parentNode.insertBefore(js, fjs)
  })
}

export function toFacebook (form, event) {
  log.debug('toFacebook', form, event)
  const { title, venue, startDate, endDate, salesStartDate, type } = event
  const { assetId, description, category, isCanceled } = form
  const isOnline = type === 'ONLINE'

  const { global } = store.state
  const { affiliateId } = store.getters
  const baseUrl = global.shop2Url

  const fallbackCover = 'https://dummyimage.com/1200x628/ff0091/fff.jpg'
  let cover = assetId ? `${baseUrl}/api/image/${assetId}/shop_cover_v3/png` : fallbackCover
  if (cover.indexOf('.light-dev.de') !== -1) cover = fallbackCover
  if (cover.indexOf('.light-stg.de') !== -1) cover = fallbackCover

  return {
    address: !isOnline ? location(venue) : undefined,
    place_id: isOnline ? '114829128532877' : undefined,
    admins: [form.pageId],
    cover: { source: cover },
    name: upperFirst(trim(title)),
    description: trim(description),
    timezone: global.country.timezone,
    start_time: moment(startDate).format(),
    end_time: endDate ? moment(endDate).format() : undefined,
    ticket_uri_start_sales_time: salesStartDate ? moment(salesStartDate).format() : undefined,
    create_as_draft: false,
    ticket_uri: `${baseUrl}/a/${affiliateId}/e/${event.id}?eventref=fb_oea`,
    category,
    is_canceled: isCanceled || undefined,
    is_online: isOnline
  }
}

export function toLight (facebook, form, event) {
  log.debug('toLight', facebook, form, event)
  const { id: eventId, name: title, start_time: startDate, place: { name: address } = {}, category, description } = facebook
  return {
    eventId: form.eventId || eventId,
    pageId: form.pageId,
    assetId: form.assetId,
    description: form.description || description,
    category: form.category || category,
    venue: address || location(event.venue),
    startDate: startDate || moment(event.startDate).format(),
    title: title || upperFirst(event.title),
    isCanceled: false
  }
}
