
import { find, get, isString } from 'lodash-es'
import { errorKeys } from '@/utils/common'

export default {
  props: {
    error: { required: false },
    'hide-ref': { required: false, type: Boolean, default: false }
  },
  computed: {
    body () {
      const error = this.error || {}
      const responseData = get(error, 'response.data') || {}
      const response = get(error, 'response') || {}
      return { status: error, ...error, ...response, ...responseData }
    },
    errorCode () {
      const { body: error } = this
      if (!error) return null
      if (isString(error)) return error
      if (error.errorCode) return error.errorCode
      if (error.status) return error.status
      return null
    },
    code () {
      const { body: error } = this
      if (!error) return null
      if (isString(error)) return error
      if (error.errorCode) return error.errorCode
      if (error.data != null && error.status != null) return `${error.status} / ${JSON.stringify(error.data)}`
      if (error.status) return error.status
      return null
    },
    message () {
      const { errorCode: code, body: error } = this
      const keys = errorKeys(code, { prefix: 'error-text.' })
      const key = find(keys, (k) => this.$te(k)) || 'error-text.default'
      return this.$t(key, { code, message: error ? error.message : null })
    },
    validations () {
      const { body: error } = this
      if (error == null || error.validationErrors == null) return null
      return error.validationErrors
    },
    title () {
      const { errorCode: code } = this
      const keys = errorKeys(code, { prefix: 'error-title.' })
      const key = find(keys, (k) => this.$te(k)) || 'error-title.default'
      return this.$t(key)
    },
    reference () {
      const { code } = this
      return this.$t('error.reference', { code: code || 'EVT-CLI' })
    }
  }
}
