import { get, find, kebabCase, isFunction, last, isObject } from 'lodash-es'
import log from 'loglevel'

export default {
  data () {
    return {
      showErrors: false,
      hasErrors: false
    }
  },

  computed: {
    $allErrors () {
      return this.$getDeepErrors({ $v: this.$v }, '$v')
    },
    $validationRules () {
      return isFunction(this.$options.validations) ? this.$options.validations.call(this) : this.$options.validations
    }
  },

  methods: {
    $resetErrors () {
      this.showErrors = false
      this.hasErrors = false
    },
    $validateAll ({ scroll = true, scrollOnlyOnError = false } = {}) {
      if (scroll && !scrollOnlyOnError) window.scroll(0, 0)
      this.showErrors = true
      this.hasErrors = !!this.$v.$invalid || this.customErrors != null
      if (scrollOnlyOnError && this.hasErrors) window.scroll(0, 0)
      if (this.hasErrors) log.debug('Validation failed with errors', this.$allErrors)
      return Promise.resolve(!this.hasErrors)
    },
    $fieldsError (...paths) {
      const opts = {}
      if (isObject(last(paths))) Object.assign(opts, paths.pop())
      return paths.reduce((m, path) => m.concat(this.$fieldError(path, opts)), [])
    },
    $fieldError (path, { label, labelKey, field, prefix, shopPreviewError } = {}) {
      if (!this.showErrors && !shopPreviewError) return []
      const errors = this.$allErrors.filter(e => prefix ? e.path.indexOf(path) === 0 : (e.key === path || e.path === path))
      if (errors.length === 0) return []
      const { key, error, params, path: fullPath, fieldName } = errors[0]
      const kebabKey = kebabCase(field || key)
      const kebabError = kebabCase(error)
      const messageKeys = [
        `validation.${kebabKey}.${kebabError}`,
        `validation.${kebabError}`
      ]
      const messageKey = messageKeys.find((key) => this.$te(key)) || messageKeys[0]

      // disabled: label is already translated, may use labelKey
      // if (label != null) labelKey = `fields.${label}`
      if (labelKey != null) labelKey = labelKey.split('.').map(kebabCase).join('.')

      return [this.$t(messageKey, Object.assign({}, params || {}, {
        attribute: key,
        path: fullPath,
        field: label || (labelKey != null ? this.$t(labelKey) : fieldName),
        value: get(this, fullPath)
      }))]
    },
    $hasFieldError (...paths) {
      return find(paths, (path) => this.$fieldError(path).length > 0) != null
    },
    $getDeepErrors (outerObj, outerKey, prefix) {
      if (!prefix) prefix = ''
      if (prefix && prefix.indexOf('$') === 0) prefix = ''
      const obj = outerObj[outerKey]
      const fullKey = prefix + outerKey
      return Object.keys(obj).reduce((acc, key) => {
        if (key.charAt(0) === '$' && key !== '$store' && key !== '$each') return acc
        if (typeof obj[key] === 'object') {
          return acc.concat(this.$getDeepErrors(obj, key, fullKey + '.'))
        }
        if (!obj[key]) {
          const fieldName = this.$t(`fields.${kebabCase(outerKey)}`)
          acc.push({
            key: outerKey,
            path: fullKey,
            error: key,
            params: obj.$params[key],
            fieldName: fieldName
          })
        }
        return acc
      }, [])
    },
    $getShallowErrors (obj) {
      return Object.keys(obj).filter(key => key.charAt(0) !== '$').filter(key => !obj[key])
    }
  },

  created () {
    if (!this.$v) {
      throw new Error(
        `vuelidate-helper is meant to be used in a component with vuelidate validations. ${this.name} does not have a $v validation object.`
      )
    }
  }
}
