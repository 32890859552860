<template>
  <v-card v-if="agreement">
    <v-card-title>
      {{ agreement.title }}
      <v-spacer />
      <v-icon @click="closeDialog">
        mdi-close
      </v-icon>
    </v-card-title>
    <hr role="separator" aria-orientation="horizontal" class="pb-2 v-divider theme--light">
    <v-card-text>
      <div class="dialog__contents my-5" v-html="agreement.contents" />
      <div class="d-flex justify-center mb-2">
        <v-btn
          large
          depressed
          color="primary"
          :href="agreementUrl"
          target="_blank"
          @click="downloadedPDF = true"
        >
          {{ $t('button.download-agreement') }}
        </v-btn>
      </div>
    </v-card-text>
    <hr role="separator" aria-orientation="horizontal" class="pb-2 v-divider theme--light">
    <v-card-actions class="px-4 pb-4 justify-space-between">
      <v-btn
        large
        depressed
        :disabled="!downloadedPDF"
        @click="declineAgreement"
      >
        {{ $t('button.decline') }}
      </v-btn>
      <v-btn
        large
        depressed
        color="primary"
        :disabled="!downloadedPDF"
        @click="acceptAgreement"
      >
        {{ $t('button.agree') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useStore } from '@/store/store'
import { apiPath } from '@/config'

const store = useStore()
const agreement = computed(() => store.getters.currentAgreement)
const agreementUrl = computed(() => `${apiPath}/agreement/${agreement.value.uuid}/pdf?access_token=${store.state.auth.token}`)

const downloadedPDF = ref(false)

function closeDialog () {
  store.commit('setShowAgreementDialog', { showAgreementDialog: false, currentAgreement: null  })
  downloadedPDF.value = false
}

function acceptAgreement () {
  store.dispatch('acceptAdditionalAgreements', { item: agreement.value }).then(() => {
    store.dispatch('notify', { key: 'notify.accepted-agreement' })
    closeDialog()
  })
}

function declineAgreement () {
  store.dispatch('declineAdditionalAgreements', { item: agreement.value }).then(() => {
    store.dispatch('notify', { key: 'notify.declined-agreement', color: 'error'  })
    closeDialog()
  })
}
</script>

<style lang="scss" scoped>
.dialog {
  &__contents {
    max-height: 400px;
    overflow-y: scroll;
  }
}
</style>
