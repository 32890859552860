import { load as loadGtm } from '@/utils/gtm'
import log from 'loglevel'

export default {
  namespaced: false,
  state: () => ({
    active: false,
    dataLayer: []
  }),
  actions: {
    GTM_PUSH_PAGE: ({ dispatch, rootState: { config: { countryId: country } }, rootGetters: { language } }) => {
      dispatch('GTM_PUSH', { event: 'PageMeta', country, language })
    },
    GTM_PUSH_VIEW ({ dispatch }, { pagePath, pageTitle }) {
      dispatch('GTM_PUSH', { event: 'virtPath', page_path: pagePath, page_title: pageTitle })
    },
    GTM_PUSH_LOGIN: ({ dispatch, rootGetters: { affiliate } }) => {
      if (affiliate == null) return
      dispatch('GTM_PUSH',   {
        event: 'gaEvent',
        event_name: 'login',
        login: {
          event_category: 'Login',
          event_action: 'Successful',
          user_status: 'Completed',
          affiliate_name: `${affiliate.name}`,
          affiliate_id: `${affiliate.id}`,
          affiliate_status: 'Completed'
        }
      })
    },
    GTM_PUSH: ({ commit, rootState: { global: { googleTagContainerId } } }, item) => {
      if (item == null) return
      if (process.env.VUE_ENV === 'server' || !googleTagContainerId) return
      if (!window.dataLayer) window.dataLayer = []
      commit('GTM_PUSH', item)
      log.debug('gtm', item)
    },
    GTM_ACTIVATE: ({ commit, state: { active }, rootState: { global: { googleTagContainerId } } }) => {
      if (active) return
      if (googleTagContainerId) {
        commit('GTM_ACTIVATED')
        // dispatch('GTM_PUSH', ['consent', 'default', { ad_storage: 'denied', analytics_storage: 'denied', wait_for_update: 500 }])
        // dispatch('GTM_PUSH', { event: 'default_consent' })
        loadGtm(googleTagContainerId)
      }
    },
    GTM_DEACTIVATE: ({ state: { active } }) => {
      if (active) window.location.reload(true)
    }
  },
  mutations: {
    GTM_PUSH (state, item) {
      state.dataLayer.push(item)
      window.dataLayer.push(item)
    },
    GTM_ACTIVATED (state) {
      state.active = true
    }
  }
}
