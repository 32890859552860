import Vue from 'vue'
// import axios from 'axios'
// import { apiPath } from './../../config'
// import qs from 'qs'
import log from 'loglevel'
import { toFacebook, toLight } from '@/utils/facebook'
import { find } from 'lodash-es'

const TOKEN_EXPIRED = 190

export default {
  namespaced: true,
  state: () => {
    return {
      loading: false,
      authentication: null,
      user: null,
      picture: null,
      pages: null,
      events: {},
      categories: ['ART_EVENT', 'BOOK_EVENT', 'MOVIE_EVENT', 'FUNDRAISER', 'VOLUNTEERING', 'FAMILY_EVENT', 'FESTIVAL_EVENT', 'NEIGHBORHOOD', 'RELIGIOUS_EVENT', 'SHOPPING', 'COMEDY_EVENT', 'MUSIC_EVENT', 'DANCE_EVENT', 'NIGHTLIFE', 'THEATER_EVENT', 'DINING_EVENT', 'FOOD_TASTING', 'CONFERENCE_EVENT', 'MEETUP', 'LECTURE', 'WORKSHOP', 'FITNESS', 'SPORTS_EVENT', 'OTHER']
    }
  },
  mutations: {
    loading: (state, v) => {
      state.loading = v === true
    },
    authentication: (state, authentication) => {
      state.authentication = authentication
      if (authentication == null) {
        Object.assign(state, { user: null, pages: null, picture: null, events: {} })
      }
    },
    user: (state, user) => {
      state.user = user
    },
    pages: (state, pages) => {
      state.pages = pages
    },
    event: (state, event) => {
      Vue.set(state.events, event.id, event)
    },
    resetEvent: (state, id) => {
      Vue.set(state.events, id, null)
    },
    deleteEvent: (state, { id }) => {
      Vue.set(state.events, id, null)
    },
    picture: (state, picture) => {
      state.picture = picture
    }
  },
  getters: {
  },
  actions: {
    login: ({ commit, dispatch }) => {
      commit('loading', true)
      return new Promise((resolve, reject) => {
        window.FB.login((res) => {
          log.debug('facebook/login', res)
          const { authResponse, status } = res

          if (status === 'unknown') {
            commit('loading', false)
            return reject(status)
          }

          if (res.error) {
            commit('loading', false)
            return reject(res.error)
          }

          commit('authentication', authResponse)

          Promise.all([
            dispatch('fetchUser'),
            dispatch('fetchPages'),
            dispatch('fetchProfilePicture')
          ]).then(() => {
            commit('loading', false)
            resolve()
          })
        }, { scope: 'pages_read_engagement,public_profile,pages_manage_posts,business_management', return_scopes: true })
      })
    },
    restore: ({ commit, dispatch }) => {
      return new Promise((resolve) => {
        commit('loading', true)
        window.FB.getLoginStatus((res) => {
          log.debug('facebook/restore', res)
          const { status, authResponse } = res
          if (status === 'connected') {
            commit('authentication', authResponse)
            Promise.all([
              dispatch('fetchUser'),
              dispatch('fetchPages'),
              dispatch('fetchProfilePicture')
            ]).then(() => {
              commit('loading', false)
              resolve()
            })
          } else {
            commit('loading', false)
            resolve()
          }
        })
      })
    },
    fetchUser: ({ commit, state: { authentication } }) => {
      if (authentication == null) return Promise.reject(new Error('Not authenticated'))
      return new Promise((resolve, reject) => {
        window.FB.api('/me', (data) => {
          log.debug('facebook/fetchUser', data)

          if (data.error) {
            commit('loading', false)
            return reject(data.error)
          }

          commit('user', data)
          resolve(data)
        })
      })
    },
    fetchProfilePicture: ({ commit, state: { authentication } }) => {
      if (authentication == null) return Promise.reject(new Error('Not authenticated'))
      return new Promise((resolve, reject) => {
        window.FB.api('/me/picture?redirect=0', (res) => {
          log.debug('facebook/fetchProfilePicture', res)

          if (res.error) {
            commit('loading', false)
            return reject(res.error)
          }

          commit('picture', res.data)
          resolve(res.data)
        })
      })
    },
    fetchPages: ({ commit, state: { authentication } }) => {
      if (authentication == null) return Promise.reject(new Error('Not authenticated'))
      return new Promise((resolve, reject) => {
        window.FB.api('/me/accounts', (res) => {
          log.debug('facebook/fetchPages', res)

          if (res.error) {
            commit('loading', false)
            return reject(res.error)
          }

          commit('pages', res.data)
          resolve(res.data)
        })
      })
    },
    fetchEvent: ({ commit, rootState: { event: { items } }, state: { pages, authentication } }, { form }) => {
      if (authentication == null) return Promise.reject(new Error('Not authenticated'))
      const page = find(pages, { id: form.pageId })
      if (page == null) return Promise.reject(new Error('Page not accessible'))
      const event = items[form.id]
      if (event == null) return Promise.reject(new Error('Event not accessible'))

      return new Promise((resolve, reject) => {
        commit('loading', true)

        window.FB.api(`/${form.eventId}?fields=category,description,name,place,start_time,cover,is_canceled`, (data) => {
          log.debug('facebook/fetchEvent', data)

          if (data.error) {
            if (data.error.code === TOKEN_EXPIRED) {
              commit('authentication', null)
            }

            commit('loading', false)
            return reject(data.error)
          }

          commit('event', data)
          commit('loading', false)
          resolve(event)
        })
      })
    },
    publish: ({ state: { pages, authentication }, rootState: { event: { items } }, commit, dispatch }, { form }) => {
      if (authentication == null) return Promise.reject(new Error('Not authenticated'))
      const page = find(pages, { id: form.pageId })
      if (page == null) return Promise.reject(new Error('Page not accessible'))
      const event = items[form.id]
      if (event == null) return Promise.reject(new Error('Event not accessible'))

      return new Promise((resolve, reject) => {
        commit('loading', true)
        const facebook = { ...toFacebook(form, event) }
        log.debug('prepared for facebook/publish', facebook, { form, event })
        if (form.eventId) commit('resetEvent', form.eventId)
        const endpoint = !form.eventId ? `/official_events?access_token=${page.access_token}` : `/${form.eventId}?access_token=${page.access_token}`
        window.FB.api(endpoint, 'post', facebook, (res) => {
          log.debug('facebook/publish', res)

          if (res.error) {
            commit('loading', false)
            return reject(res.error)
          }

          const light = { ...toLight(res, form, event) }
          dispatch('patchEvent', { item: { id: event.id, facebook: light } }, { root: true }).then(() => {
            commit('loading', false)
            resolve()
          }).catch((error) => {
            commit('loading', false)
            reject(error)
          })
        })
      })
    },
    delete: ({ state: { pages, authentication }, commit, dispatch }, { form: { id, eventId, pageId } }) => {
      if (authentication == null) return Promise.reject(new Error('Not authenticated'))
      const page = find(pages, { id: pageId })
      if (!page) return Promise.reject(new Error('Page not accessible'))
      return new Promise((resolve, reject) => {
        commit('loading', true)
        window.FB.api(`/${eventId}?access_token=${page.access_token}`, 'delete', (res) => {
          log.debug('facebook/delete', res)

          if (res.error) {
            commit('loading', false)
            return reject(res.error)
          }

          commit('deleteEvent', eventId)
          dispatch('patchEvent', { item: { id, facebook: {} } }, { root: true }).then(() => {
            commit('loading', false)
            resolve()
          }).catch((error) => {
            commit('loading', false)
            reject(error)
          })
        })
      })
    },
    logout: ({ commit }) => {
      return new Promise((resolve, reject) => {
        commit('loading', true)
        window.FB.logout(function (res) {
          log.debug('facebook/logout', res)

          if (res.error) {
            commit('loading', false)
            return reject(res.error)
          }

          commit('authentication', null)
          commit('loading', false)
          resolve()
        })
      })
    },
    revoke: ({ commit, dispatch }) => {
      return new Promise((resolve, reject) => {
        commit('loading', true)
        window.FB.api('/me/permissions', 'delete', function (res) {
          log.debug('facebook/revoke', res)

          if (res.error) {
            commit('loading', false)
            return reject(res.error)
          }

          Promise.all([
            dispatch('logout'),
          ]).then(() => {
            commit('loading', false)
            resolve()
          })
        })
      })
    }
  }
}
