import ErrorView from './Error.vue'
import { find } from 'lodash-es'
import { errorKeys } from '@/utils/common'

export function createErrorView ({ error }) {
  const { errorCode = 'default' } = error
  return {
    name: `error-${errorCode}-view`,

    metaInfo () {
      const keys = errorKeys(errorCode, { prefix: 'error-title.' })
      const key = find(keys, (k) => this.$te(k)) || 'error-title.default'
      return {
        title: this.$t(key)
      }
    },

    render (h) {
      return h(ErrorView, { props: { error } })
    }
  }
}
