import axios from 'axios'
import { apiPath } from './../../config'

export default {
  namespaced: false,
  state: () => ({
    busy: false
  }),
  actions: {
    register: (_, { data: payload }) => {
      return new Promise((resolve, reject) => {
        axios.post(`${apiPath}/registration`, payload)
          .then(({ data }) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    activate: (_, { data: payload }) => {
      return new Promise((resolve, reject) => {
        axios.put(`${apiPath}/registration/verification`, payload)
          .then(() => {
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    resetPassword: (_, { password, code }) => {
      return new Promise((resolve, reject) => {
        axios.put(`${apiPath}/registration/forgotPassword`, {
          newPassword: password,
          repeatPassword: password,
          token: code
        })
          .then(() => {
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  }
}
