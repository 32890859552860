import Vue from 'vue'
import axios from 'axios'
import { apiPath } from './../../config'

export default {
  namespaced: false,
  state: () => {
    return {
      reports: {},
      times: {}
    }
  },
  mutations: {
    findEventReportResult: (state, { item, id }) => {
      Vue.set(state.reports, id, item)
    },
    findEventTimeResult: (state, { item, id }) => {
      Vue.set(state.times, id, item)
    }
  },
  getters: {},
  actions: {
    findEventReport: ({ commit }, { id }) => {
      return axios.get(`${apiPath}/event/${id}/report`)
        .then(({ data }) => {
          commit('findEventReportResult', { item: data, id: id })
        })
        .catch((error) => {
          throw error
        })
    },
    findEventTimes: ({ commit }, { id }) => {
      return axios.get(`${apiPath}/event/${id}/report/time`)
        .then(({ data }) => {
          commit('findEventTimeResult', { item: data, id: id })
        })
        .catch((error) => {
          throw error
        })
    }
  }
}
