import axios from 'axios'
import { apiPath } from './../../config'
import QS from 'qs'

export default {
  namespaced: false,
  actions: {
    findLocations: ({ rootState: { global: { country: { iso: country } } } }, { query: q }) => {
      const query = QS.stringify({ country, q })
      return new Promise((resolve, reject) => {
        axios.get(`${apiPath}/venue/search?${query}`)
          .then(({ data }) => { resolve(data) })
          .catch((error) => reject(error))
      })
    }
  }
}
