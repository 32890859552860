import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { i18n } from '@/i18n'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        brand: '#202867',
        primary: '#2a63d1',
        secondary: {
          base: '#999999',
          darken1: '#F5F6F7'
        },
        secondaryLight: '#c0c0c0',
        accent: '#FFBF5F',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      }
    }
  },
  lang: {
    t: (key, ...params) => i18n.t(key.indexOf('$vuetify.') === 0 ? key.substr(9) : key, params)
  },
  icons: {
    iconfont: 'md'
  }
})
